import { useTranslation } from '@i18n';

import useAuthCustomer from '@root/src/hooks/useAuthCustomer';
import { getCustomerList as gqlGetCustomerList } from '@modules/sellerpromotion/services/graphql';

import { addActiveSession, createFirebaseDoc } from '../../services/graphql/index';

const FindBuyerCore = ({ Content, onClose, ...restProps }) => {
    const { t } = useTranslation(['chat']);

    const { loading: loadingAuthUser, customer: authUser } = useAuthCustomer();

    const [getCustomerList, {
        data, loading, fetchMore,
    }] = gqlGetCustomerList();

    const [createSession, { loading: loadingCreateSession }] = addActiveSession();
    const [createFbDoc, { loading: loadingCreateFbDoc }] = createFirebaseDoc();

    const [selectedBuyer, setSelectedBuyer] = React.useState({});
    const [searchText, setSearchText] = React.useState('');
    const [closeSearch, setCloseSearch] = React.useState(false);

    const isLoading = loadingAuthUser || loading;
    const isLoadingStartChat = loadingCreateFbDoc || loadingCreateSession;

    const changeSerchUser = React.useCallback((e) => {
        if (e.target.value === '') {
            setSearchText('');
        } else {
            setSearchText(e.target.value);
        }
    }, [setSearchText]);

    const handleSearchUser = React.useCallback(async (e) => {
        e.preventDefault();
        setCloseSearch(true);

        try {
            if (authUser?.seller_code) {
                await getCustomerList({
                    variables: {
                        filter: {
                            company_name: {
                                in: searchText.toLowerCase(),
                            },
                            vendor_code: {
                                in: authUser.seller_code,
                            },
                            customer_status: {
                                eq: 'approved',
                            },
                            customer_active: {
                                eq: '1',
                            },
                        },
                        pageSize: 9999,
                        currentPage: 1,
                    },
                });
            }
        } catch (err) {
            window.toastMessage({
                open: true,
                text: err.message,
                variant: 'error',
            });
        }
    }, [getCustomerList, authUser, searchText]);

    const handleClearSearch = React.useCallback(async (e) => {
        e.preventDefault();
        setCloseSearch(false);
        setSearchText('');

        try {
            if (authUser?.seller_code) {
                await getCustomerList({
                    variables: {
                        filter: {
                            vendor_code: {
                                in: authUser.seller_code,
                            },
                            customer_status: {
                                eq: 'approved',
                            },
                            customer_active: {
                                eq: '1',
                            },
                        },
                        pageSize: 10,
                        currentPage: 1,
                    },
                });
            }
        } catch (err) {
            window.toastMessage({
                open: true,
                text: err.message,
                variant: 'error',
            });
        }
    }, [getCustomerList, authUser, searchText]);

    const startChat = async (buyer) => {
        if (!buyer) return;

        setSelectedBuyer(buyer);

        const {
            chat_agent_code: agent_code,
            group: { code: agent_name },
        } = authUser;
        const { customer_email, company_name: customer_name } = buyer;

        try {
            await createFbDoc({
                variables: {
                    agent_code,
                    agent_name,
                    customer_email,
                    customer_name,
                },
            });

            await createSession({
                variables: { agent_code, customer_email },
            });
        } catch (err) {
            window.toastMessage({
                open: true,
                text: err.message ?? t('chat:findbuyer:messageFailed', { customer_name }),
                variant: 'error',
            });
            setSelectedBuyer({});
        }
    };

    React.useEffect(() => {
        if (authUser?.seller_code) {
            getCustomerList({
                variables: {
                    filter: {
                        vendor_code: {
                            in: authUser.seller_code,
                        },
                        customer_status: {
                            eq: 'approved',
                        },
                        customer_active: {
                            eq: '1',
                        },
                    },
                    pageSize: 10,
                    currentPage: 1,
                },
            });
        }
    }, [authUser?.seller_code]);

    return (
        <Content
            t={t}
            loading={isLoading}
            loadingStartChat={isLoadingStartChat}
            authUser={authUser}
            buyerList={data?.getCustomerList?.data ?? []}
            buyerTotal={data?.getCustomerList?.total_count ?? 0}
            selectedBuyer={selectedBuyer}
            startChat={startChat}
            onClose={onClose}
            fetchMore={fetchMore}
            changeSerchUser={changeSerchUser}
            handleSearchUser={handleSearchUser}
            searchText={searchText}
            closeSearch={closeSearch}
            handleClearSearch={handleClearSearch}
            {...restProps}
        />
    );
};

export default FindBuyerCore;
