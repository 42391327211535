/* eslint-disable import/prefer-default-export */
export const optionsPickPack = (t) => [
    {
        aclCode: 'header_pick_pack',
        key: 'pickpack',
        label: t('menu:menuPickPack:parent'),
        children: [
            {
                aclCode: 'pick_by_wave_list',
                key: 'wavelist',
                label: t('menu:menuPickPack:pickList'),
                url: '/pickpack/wavelist',
            },
            {
                aclCode: 'pick_by_wave_create',
                key: 'wavecreate',
                label: t('menu:menuPickPack:createPickByWave'),
                url: '/pickpack/wavecreate',
            },
            {
                aclCode: 'pick_by_wave_packlist',
                key: 'wavepack',
                label: t('menu:menuPickPack:packList'),
                url: '/pickpack/wavepack',
            },
            {
                aclCode: 'pick_by_batch_list',
                key: 'batchlist',
                label: t('menu:menuPickPack:batchList'),
                url: '/pickpack/batchlist',
            },
            {
                aclCode: 'pick_by_batch_create',
                key: 'batchcreate',
                label: t('menu:menuPickPack:createPickByBatch'),
                url: '/pickpack/batchcreate',
            },
            {
                aclCode: 'pick_by_batch_packlist',
                key: 'batchpack',
                label: t('menu:menuPickPack:packList'),
                url: '/pickpack/batchpack',
            },
        ],
    },
];
