import { gql } from '@apollo/client';

export const salesRuleGetList = gql`
    query salesRuleGetList($pageSize: Int!, $currentPage: Int!, $filter: SalesRuleRequestInput, $sort: SalesRuleRequestSortInput) {
        salesRuleGetList(pageSize: $pageSize, currentPage: $currentPage, filter: $filter, sort: $sort) {
            page_info {
                current_page
                page_size
                total_pages
            }
            sales_rules {
                customer_email
                coupon_code
                apply_to_shipping
                coupon_type
                customer_group_ids
                description
                discount_qty
                discount_amount
                discount_step
                from_date
                is_active
                is_advanced
                is_rss
                name
                product_ids
                rule_id
                simple_action
                simple_free_shipping
                sku
                sort_order
                stop_rules_processing
                subtotal
                times_used
                to_date
                uses_per_coupon
                use_auto_generation
                uses_per_customer
                vendor_code
                website_ids
            }
            total_count
        }
    }
`;

export const createSalesRule = gql`
    mutation createSalesRule(
        $discount_amount: Float!
        $name: String!
        $description: String
        $simple_action: String!
        $customer_group_ids: [String!]!
        $is_active: Boolean
        $from_date: String
        $to_date: String
        $discount_qty: Int
        $discount_step: Int
        $coupon_code: String
        $vendor_code: String
        $sku: [String]
        $customer_email: String
        $uses_per_coupon: Int
        $uses_per_customer: Int
        $sku_x: String
        $sku_y: String
        $subtotal: Float
    ) {
        createSalesRule(
            input: {
                discount_amount: $discount_amount
                name: $name
                description: $description
                simple_action: $simple_action
                customer_group_ids: $customer_group_ids
                is_active: $is_active
                from_date: $from_date
                to_date: $to_date
                discount_qty: $discount_qty
                discount_step: $discount_step
                coupon_code: $coupon_code
                vendor_code: $vendor_code
                sku: $sku
                customer_email: $customer_email
                uses_per_coupon: $uses_per_coupon
                uses_per_customer: $uses_per_customer
                sku_x: $sku_x
                sku_y: $sku_y
                subtotal: $subtotal
            }
        ) {
            item {
                apply_to_shipping
                coupon_type
                customer_group_ids
                description
                discount_amount
                discount_qty
                discount_step
                from_date
                is_active
                is_advanced
                is_rss
                name
                product_ids
                rule_id
                simple_action
                simple_free_shipping
                sort_order
                stop_rules_processing
                times_used
                to_date
                use_auto_generation
                uses_per_coupon
                uses_per_customer
                vendor_code
                sku
                subtotal
            }
        }
    }
`;

export const updateSalesRule = gql`
    mutation updateSalesRule(
        $rule_id: Int!
        $discount_amount: Float
        $name: String
        $description: String
        $simple_action: String!
        $customer_group_ids: [String!]
        $is_active: Boolean
        $from_date: String
        $to_date: String
        $discount_qty: Int
        $discount_step: Int
        $coupon_code: String
        $vendor_code: String
        $sku: [String]
        $customer_email: String
        $uses_per_coupon: Int
        $uses_per_customer: Int
        $sku_x: String
        $sku_y: String
        $subtotal: Float
    ) {
        updateSalesRule(
            input: {
                rule_id: $rule_id
                discount_amount: $discount_amount
                name: $name
                description: $description
                simple_action: $simple_action
                customer_group_ids: $customer_group_ids
                is_active: $is_active
                from_date: $from_date
                to_date: $to_date
                discount_qty: $discount_qty
                discount_step: $discount_step
                coupon_code: $coupon_code
                vendor_code: $vendor_code
                sku: $sku
                customer_email: $customer_email
                uses_per_coupon: $uses_per_coupon
                uses_per_customer: $uses_per_customer
                sku_x: $sku_x
                sku_y: $sku_y
                subtotal: $subtotal
            }
        ) {
            item {
                apply_to_shipping
                coupon_code
                coupon_type
                customer_email
                customer_group_ids
                description
                discount_amount
                discount_qty
                discount_step
                from_date
                is_active
                is_advanced
                is_rss
                name
                product_ids
                rule_id
                simple_action
                simple_free_shipping
                sort_order
                stop_rules_processing
                times_used
                to_date
                use_auto_generation
                uses_per_coupon
                uses_per_customer
                vendor_code
                sku
                subtotal
            }
        }
    }
`;

export const deleteCartPriceRuleCustom = gql`
    mutation deleteCartPriceRuleCustom($id: Int!) {
        deleteCartPriceRuleCustom(id: $id) {
            response
            message
        }
    }
`;

export const getCartPriceRuleDetails = gql`
    query getCartPriceRuleDetails($id: Int!, $vendor_code: String!) {
        getCartPriceRuleDetails(id: $id, vendor_code: $vendor_code) {
            customer_email
            coupon_code
            apply_to_shipping
            coupon_type
            customer_group_ids
            description
            discount_amount
            discount_qty
            discount_step
            from_date
            is_active
            is_advanced
            is_rss
            name
            product_ids
            rule_id
            simple_action
            simple_free_shipping
            sku
            sort_order
            stop_rules_processing
            times_used
            to_date
            use_auto_generation
            uses_per_coupon
            uses_per_customer
            vendor_code
            sku_x
            sku_y
            subtotal
        }
    }
`;

export const getBundlingPromoList = gql`
    query getBundlingPromoList($pageSize: Int, $currentPage: Int, $filter: SalesRuleRequestInput, $sort: SalesRuleRequestSortInput) {
        getBundlingPromoList(pageSize: $pageSize, currentPage: $currentPage, filter: $filter, sort: $sort) {
            page_info {
                current_page
                page_size
                total_pages
            }
            rules {
                 customer_group_ids
                 customer_email
                 description
                 from_date
                 is_active
                 name
                 rule_id
                 times_used
                 to_date
                 vendor_code
                 website_ids
            }
            total_count
        }
    }
`;

export const getBundlingPromoDetail = gql`
    query getBundlingPromoDetail($rule_id: Int!, $vendor_code: String!) {
        getBundlingPromoDetail(rule_id: $rule_id, vendor_code: $vendor_code) {
            discount_amount
            discount_qty
            rule_id
            sku
        }
    }
`;

export const updateBundlingPromo = gql`
    mutation updateBundlingPromo(
         $rule_id: Int!
         $name: String
         $description: String
         $customer_group_ids: [String]!
         $customer_email: String
         $is_active: Boolean
         $from_date: String
         $to_date: String
         $vendor_code: String!
         $items: [ItemBundlingPromoInput]!
    ) {
        updateBundlingPromo(
            input: {
                rule_id: $rule_id
                name: $name
                description: $description
                customer_group_ids: $customer_group_ids
                customer_email: $customer_email
                is_active: $is_active
                from_date: $from_date
                to_date: $to_date
                vendor_code: $vendor_code
                items: $items
            }
        ) {
            rules {
                 customer_group_ids
                 customer_email
                 description
                 from_date
                 is_active
                 name
                 rule_id
                 times_used
                 to_date
                 vendor_code
                 website_ids
            }
        }
    }
`;

export const createBundlingPromo = gql`
    mutation createBundlingPromo(
         $name: String!
         $description: String
         $customer_group_ids: [String]!
         $customer_email: String
         $is_active: Boolean
         $from_date: String
         $to_date: String
         $vendor_code: String!
         $items: [ItemBundlingPromoInput]!
    ) {
        createBundlingPromo(
            input: {
                name: $name
                description: $description
                customer_group_ids: $customer_group_ids
                customer_email: $customer_email
                is_active: $is_active
                from_date: $from_date
                to_date: $to_date
                vendor_code: $vendor_code
                items: $items
            }
        ) {
            rules{
                customer_group_ids
                customer_email
                description
                from_date
                is_active
                name
                rule_id
                times_used
                to_date
                vendor_code
                website_ids
            }
        }
    }
`;

export const getListCustomerGroups = gql`
    query {
        getListCustomerGroups {
            customer_group_id
            customer_group_code
        }
    }
`;

export const getCustomerList = gql`
    query getCustomerList($filter: CustomerCompanyInput, $pageSize: Int, $currentPage: Int) {
        getCustomerList(filter: $filter, pageSize: $pageSize, currentPage: $currentPage) {
            data {
                company_email
                company_id
                company_name
                customer_email
                customer_group_code
                customer_group_id
                customer_id
                customer_status
                erp_id
                firstname
                lastname
                vendor_code
            }
            page_info {
                current_page
                page_size
                total_pages
            }
            total_count
        }
    }
`;

export const getProductList = gql`
    query getProductList($pageSize: Int!, $currentPage: Int!, $filter: ProductFilterInput, $sort: ProductSortInput) {
        getProductList(pageSize: $pageSize, currentPage: $currentPage, filter: $filter, sort: $sort) {
            items {
                entity_id
                name
                sku
                product_price
                product_special_price
                product_status {
                    label
                }
                approval_status
            }
            total_count
            page_info {
                page_size
                current_page
                total_pages
            }
        }
    }
`;

export const getLocationList = gql`
    query getLocationList($pageSize: Int!, $currentPage: Int!, $filter: LocationFilterInput, $sort: LocationSortInput, $search: String) {
        getLocationList(pageSize: $pageSize, currentPage: $currentPage, filter: $filter, sort: $sort, search: $search) {
            items {
                loc_id
                loc_code
                loc_name
                loc_city {
                    id
                    label
                }
                loc_street
            }
            total_count
            page_info {
                page_size
                current_page
                total_pages
            }
        }
    }
`;

export const getSalesRuleCouponDetail = gql`
query getSalesRuleCouponDetail($rule_id: String!){
  getSalesRuleCouponDetail(rule_id: $rule_id) {
    coupon_id
    sales_rule {
      id
      name
    }
    code
    usage_limit
    usage_per_customer
    times_used
    expiration_date
    is_primary
    type
    created_at
    customers {
        id
      firstname
      lastname
      email
      times_used
      orders {
        customer_id
        order_date
        status
        increment_id
        carrier
        order_date
        items {
          discounts  {
            amount {
              currency
              value
            }
            label
          }
          image {
            label
            disabled
            position
            url
          }
          product_name
          product_type
          product_sku
          id
        }
        total {
          grand_total {
            value
            currency
          }
          subtotal {
            value
          }
          total_shipping {
            value
          }
          total_tax {
            value
          }
        }
        billing_address {
          city
          company
          country_code
          fax
          firstname
          lastname
          middlename
          postcode
          prefix
          region
          region_id
          street
          suffix
          telephone
          vat_id
        }
      }
    }
  }
}
`;

export const salesRuleExport = gql`
query salesRuleExport($filter: SalesRuleRequestInput, $sort: SalesRuleRequestSortInput){
    salesRuleExport(filter: $filter, sort: $sort){
        file_url
    }
}
`;

export default {
    salesRuleGetList,
    createSalesRule,
    updateSalesRule,
    deleteCartPriceRuleCustom,
    getCartPriceRuleDetails,
    getListCustomerGroups,
    getCustomerList,
    getProductList,
    getLocationList,
    getSalesRuleCouponDetail,
    salesRuleExport,
    getBundlingPromoList,
    getBundlingPromoDetail,
    updateBundlingPromo,
    createBundlingPromo,
};
