import React from 'react';
import Cookies from 'js-cookie';

import { custDataNameCookie } from '@config';
import loginGqlService from '@modules/login/services/graphql';

const useAuthCustomer = () => {
    const localCustomer = Cookies.getJSON(custDataNameCookie) || {};
    const [getCustomer, { loading, data }] = loginGqlService.getCustomer();
    const getCustomerFromGql = () => data && data.customer;

    React.useEffect(() => {
        if (!Object.keys(localCustomer).length) {
            getCustomer();
        }
    }, []);

    React.useEffect(() => {
        if (getCustomerFromGql()) {
            Cookies.set(custDataNameCookie, getCustomerFromGql());
        }
    }, [getCustomerFromGql()]);

    return {
        loading,
        customer: data?.customer || localCustomer,
    };
};

export default useAuthCustomer;
