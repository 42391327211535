import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import * as Schema from '@modules/vendorchat/services/graphql/schema';
import contextMultiple from '@helper_contextmultiple';

const context = {
    context: {
        request: 'internal',
    },
};

export const getSessionMessageList = (options = {}) => useQuery(Schema.getSessionMessageListSchema, {
    ...options,
    ...context,
});

export const getSessionMessage = (options = {}) => useLazyQuery(Schema.getSessionMessageListSchema, {
    ...options,
    ...context,
    fetchPolicy: 'network-only',
});

export const getMessageList = (options = {}) => useLazyQuery(Schema.getMessageListSchema, {
    ...options,
    ...context,
    fetchPolicy: 'network-only',
});

export const addMessage = (options = {}) => useMutation(Schema.addMessageSchema, {
    ...options,
    ...context,
});

export const terminateChatSession = (options = {}) => useMutation(Schema.terminateChatSessionSchema, {
    ...options,
    ...context,
});

export const csAllowedChat = (options = {}) => useMutation(Schema.csAllowedChatSchema, {
    ...options,
    ...context,
});

export const markUnreadMessage = (options = {}) => useMutation(Schema.markUnreadMessage, {
    ...options,
    ...context,
});

export const getAgentList = (options = {}) => useQuery(Schema.getAgentListSchema, {
    ...options,
    ...context,
});

export const addActiveSession = (options = {}) => useMutation(Schema.addActiveSessionSchema, {
    ...options,
    ...context,
});

export const createFirebaseDoc = (options = {}) => useMutation(Schema.createFirebaseDocSchema, {
    ...options,
    ...context,
});

export const getAutotextCS = (options = {}) => useQuery(Schema.getAutotextCSSchema, {
    ...options,
    ...context,
});

export const getAutotextSeller = (options = {}) => useQuery(Schema.getAutotextSellerSchema, {
    ...options,
    ...context,
});

export const getLevelConfigByEmail = (variables) => useQuery(Schema.getLevelConfigByEmail, {
    variables, ...context,
});

export const getLevelBuyerByEmail = (variables) => useQuery(Schema.getLevelBuyerByEmail, {
    variables, ...contextMultiple,
});
