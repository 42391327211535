/* eslint-disable indent */
import { gql } from '@apollo/client';

export const getCustomerToken = gql`
mutation getToken(
    $email: String!,
    $password: String!,
) {
  internalGenerateCustomerToken(email: $email, password: $password){
      token
    }
  }
`;

export const removeToken = gql`
mutation {
  internalDeleteCustomerToken{
    result
  }
}
`;

export const getCustomer = gql`
query{
  customer{
    email
    firstname
    verification_status
    lastname
    customer_id
    customer_company_code
    customer_salesman_code
    customer_loc_code
    channel_code
    chat_agent_code
    group{
      id
      code
    }
    seller_code
    level_name
    level_code
    level_logo
    company_name
  }
}
`;

export const getInternalStoreConfig = gql`
  query getStoreConfig($path: String!){
    internalGetStoreConfig(path: $path)
  }
`;

export const getStoreConfig = gql`
  query getStoreConfig($path: String!){
    getStoreConfig(path: $path)
  }
`;

export const checkCustomerValidateOtp = gql`
  mutation checkCustomerValidateOtp(
    $email: String!
  ){
    checkCustomerValidateOtp(
      email: $email
    )
  }
`;

export const getReCaptchaConfig = gql`
  query {
    getReCaptchaConfig{
      private_key
      public_key
    }
  }
`;

export const setDeviceInfo = gql`
  query setDeviceInfo(
    $device: String!,
    $ip_address: String!,
    $location: String!,
    $user_agent: String!,
  ){
    setDeviceInfo(
      input: {
        device: $device,
        ip_address: $ip_address,
        location: $location,
        user_agent: $user_agent,
      }
    ) {
      message
      success
    }
  }
`;

export const getXSiteToken = gql`
mutation internalGenerateNewToken(
    $token: String!,
) {
  internalGenerateNewToken(token: $token) {
      token
    }
  }
`;

export default {
  getCustomerToken,
  removeToken,
  getCustomer,
  getStoreConfig,
  getInternalStoreConfig,
  checkCustomerValidateOtp,
  getReCaptchaConfig,
  setDeviceInfo,
  getXSiteToken,
};
