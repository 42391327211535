/* eslint-disable import/prefer-default-export */
export const optionsLoyalty = (t) => [
    {
        aclCode: 'oms_lite_header_vendor_portal',
        key: 'loyalty',
        label: t('menu:menuLoyalty:parent'),
        children: [
            {
                aclCode: 'oms_lite_membership_benefit',
                key: 'benefit',
                label: t('menu:menuLoyalty:benefit'),
                url: '/loyalty/benefit',
            },
            {
                aclCode: 'oms_lite_store_perfomance',
                key: 'performance',
                label: t('menu:menuLoyalty:performance'),
                url: '/loyalty/performance',
            },
        ],
    },
];
