/* eslint-disable */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
/* eslint-disable consistent-return */
import React from 'react';
import clsx from 'clsx';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import Hidden from '@material-ui/core/Hidden';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Badge from '@material-ui/core/Badge';
import { useRouter } from 'next/router';
import { makeStyles } from '@material-ui/core/styles';
import { miniDrawerWidth, drawerWidth } from '@modules/theme/layout/helpers';
import Link from 'next/link';
import {
 CYAN_BOLD, BLUE_BACKGROUND, COLOR_INVERT, ORANGE_PRIMARY,
} from '@theme_color';
// import gqlService from '@modules/orderqueue/services/graphql';
import useAuthCustomer from '@core/hooks/useAuthCustomer';
import { getGroupDontHaveDashboard } from '@modules/theme/helpers/menu';

const useStyles = makeStyles((theme) => ({
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: miniDrawerWidth,
        },
    },
    togleMenuButton: {
        width: 24,
        height: 24,
        position: 'absolute',
        top: '16px',
        right: '16px',
    },
    togleMenuIcon: {
        fontSize: 27,
        color: `${CYAN_BOLD}`,
        borderRadius: '3px',
        background: '#fff',
        boxShadow: '0px 3px 6px #DDE1EC',
    },
    toolbar: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    },
    swiftOmsLogo: {
        cursor: 'pointer',
        padding: 12,
        '&.open': { justifyContent: 'flex-start' },
        '&.close': { justifyContent: 'center' },
        '& img': { maxHeight: 45 },
    },
    divMenu: {
        color: '#B1BCDB',
        '& .itemText span': {
            fontWeight: 700,
            fontSize: 15,
            textTransform: 'capitalize',
        },
    },
    menuList: {
        padding: 0,
        '&.open': { paddingRight: 16 },
    },
    menuItem: {
        marginTop: 8,
        paddingLeft: 20,
        '&.open': { borderRadius: '0 26px 26px 0' },
        '&:hover': {
            background: `${BLUE_BACKGROUND}`,
            color: `${CYAN_BOLD}`,
        },
        '&.active': {
            background: `${BLUE_BACKGROUND}`,
            color: `${CYAN_BOLD}`,
            '& .itemIcon img': {
                filter: `${COLOR_INVERT}`,
            },
        },
        '& .itemIcon': {
            minWidth: 50,
        },
    },
    menuChildItem: {
        paddingLeft: 70,
        '&.active span': {
            color: `${CYAN_BOLD}`,
            fontWeight: 'bold',
        },
        '&:hover span': {
            color: `${CYAN_BOLD}`,
            fontWeight: 'bold',
        },
        '&:hover': {
            background: 'transparent',
        },
    },
    customBadge: {
        backgroundColor: `${ORANGE_PRIMARY}`,
        color: 'white',
    },
}));

const Sidebar = ({
    activeParentMenu,
    setActiveParentMenu,
    activeChildMenu,
    setActiveChildMenu,
    open,
    setOpen,
    menuList,
    aclDetail,
    storeConfigDetailBatch,
    storeConfigDetailWave,
    storeLogo,
    storeConfigDetailTada,
    storeConfigDetailVendor,
    storeConfigBeneficiaries,
}) => {
    const router = useRouter();
    const classes = useStyles();
    const { customer: authCustomer, loading: loadingAuthCustomer } = useAuthCustomer();

    const authCustomerGroupCode = authCustomer?.group?.code;
    const groupHaveDashboard = !loadingAuthCustomer && !getGroupDontHaveDashboard(authCustomerGroupCode);

    let orderCounter = [];

    const handleClickParent = (menu) => {
        if (menu.key === (activeParentMenu && activeParentMenu.key)) {
            setActiveParentMenu(null);
        } else {
            setActiveParentMenu(menu);
            if (menu.url) router.push(menu.url);
        }
        setOpen(true);
    };
    const handleClickChild = (menu) => {
        setActiveChildMenu(menu);
        if (menu.url) router.push(menu.url);
        setOpen(true);
    };

    // const { data, loading } = gqlService.getOrderCounter();

    // if (!loading) {
    //     orderCounter = data && data.getOrderCounter;
    // }

    const counterMapping = (statusCode, orderCounters) => {
        return 0;
        // const filterCounter = orderCounters?.filter((menu) => menu?.status === statusCode);
        // return (filterCounter && filterCounter[0] && filterCounter[0].total) || 0;
    };

    const formatNum = (n) => {
        if (n < 101) return n.toString();
        if (n > 100 && n <= 1000) return '100+';
        if (n >= 1e3) return `${+Math.floor(n / 1000) }K`;
    };

    const SidebarContent = () => (
        <>
            <div
                className={clsx(classes.toolbar, classes.swiftOmsLogo, open ? 'open' : 'close')}
                onClick={() => router.push('/')}
                onKeyDown={() => router.push('/')}
            >
                <img
                    alt=""
                    src={open ? storeLogo?.logo || '/assets/img/swiftoms_logo_expanded.png'
                        : storeLogo?.favicon || '/assets/img/swiftoms_logo_collapsed.png'}
                />
            </div>
            <Hidden smUp>
                <IconButton
                    color="inherit"
                    aria-label="open drawer"
                    edge="start"
                    onClick={() => setOpen(false)}
                    className={clsx(classes.togleMenuButton)}
                >
                    <ChevronLeftIcon className={classes.togleMenuIcon} />
                </IconButton>
            </Hidden>
            <List className={clsx(classes.menuList, open ? 'open' : 'close')}>
                {menuList
                    && menuList.map((menu) => (
                        <div key={menu.key}>
                            {menu.key === 'dashboard' && groupHaveDashboard && (
                                <div className={classes.divMenu} key={menu.key}>
                                    <Link href={`${menu.url}`} key={menu.key}>
                                        <a href={`${menu.url}`}>
                                            <ListItem
                                                button
                                                className={clsx(
                                                    classes.menuItem,
                                                    open ? 'open' : 'close',
                                                    menu.key === (activeParentMenu && activeParentMenu.key) && 'active',
                                                )}
                                                onClick={() => handleClickParent(menu)}
                                                id={`sm_${menu.key}`}
                                            >
                                                <ListItemIcon className="itemIcon">
                                                    <img alt="" src={`/assets/img/layout/${menu.key}.svg`} />
                                                </ListItemIcon>
                                                <ListItemText className="itemText" primary={menu.label} />
                                            </ListItem>
                                        </a>
                                    </Link>
                                </div>
                            )}
                        </div>
                    ))}
                {menuList
                    && menuList.map((menu) => (
                        <div key={menu.key}>
                            {aclDetail[0] && (aclDetail[0].acl_code.includes(menu.aclCode) || menu.notInAcl) && !menu.hide && (
                                <div className={classes.divMenu} key={menu.key}>
                                    {(menu.key === 'tada' || menu.key === 'vendor') ? (
                                        <>
                                            {(menu.key === 'tada' && storeConfigDetailTada[0] === '1') && (
                                                <ListItem
                                                    button
                                                    className={clsx(
                                                        classes.menuItem,
                                                        open ? 'open' : 'close',
                                                        menu.key === (activeParentMenu && activeParentMenu.key) && 'active',
                                                    )}
                                                    onClick={() => handleClickParent(menu)}
                                                    id={`sm_${menu.key}`}
                                                >
                                                    <ListItemIcon className="itemIcon">
                                                        <img alt="" src={`/assets/img/layout/${menu.key}.svg`} />
                                                    </ListItemIcon>
                                                    <ListItemText className="itemText" primary={menu.label} />
                                                </ListItem>
                                            )}
                                            {(menu.key === 'vendor' && storeConfigDetailVendor[0] === '1') && (
                                                <ListItem
                                                    button
                                                    className={clsx(
                                                        classes.menuItem,
                                                        open ? 'open' : 'close',
                                                        menu.key === (activeParentMenu && activeParentMenu.key) && 'active',
                                                    )}
                                                    onClick={() => handleClickParent(menu)}
                                                    id={`sm_${menu.key}`}
                                                >
                                                    <ListItemIcon className="itemIcon">
                                                        <img alt="" src={`/assets/img/layout/${menu.key}.svg`} />
                                                    </ListItemIcon>
                                                    <ListItemText className="itemText" primary={menu.label} />
                                                </ListItem>
                                            )}
                                        </>
                                    ) : (
                                        <ListItem
                                            button
                                            className={clsx(
                                                classes.menuItem,
                                                open ? 'open' : 'close',
                                                menu.key === (activeParentMenu && activeParentMenu.key) && 'active',
                                            )}
                                            onClick={() => handleClickParent(menu)}
                                            id={`sm_${menu.key}`}
                                        >
                                            <ListItemIcon className="itemIcon">
                                                <img alt="" src={`/assets/img/layout/${menu.key}.svg`} />
                                            </ListItemIcon>
                                            <ListItemText className="itemText" primary={menu.label} />
                                        </ListItem>
                                    )}
                                    {menu && menu.children && menu.children.length && (
                                        <Collapse in={activeParentMenu && activeParentMenu.key === menu.key} timeout="auto" unmountOnExit>
                                            <List component="div" disablePadding>
                                                {menu.children.map((menuChild) => (
                                                    <div key={menuChild.key}>
                                                        {((aclDetail[0] && aclDetail[0].acl_code.includes(menuChild.aclCode))
                                                            || menuChild.notInAcl)
                                                            && !menuChild.hide && (
                                                                <>
                                                                    {menu.key === 'pickpack' && (
                                                                        <>
                                                                            {storeConfigDetailWave[0] === '1' && (
                                                                                <>
                                                                                    {(menuChild.key === 'wavelist'
                                                                                        || menuChild.key === 'wavecreate'
                                                                                        || menuChild.key === 'wavepack') && (
                                                                                        <Link href={`${menuChild.url}`} key={menuChild.key}>
                                                                                            <a>
                                                                                                <ListItem
                                                                                                    button
                                                                                                    key={menuChild.key}
                                                                                                    className={clsx(
                                                                                                        classes.menuChildItem,
                                                                                                        menuChild.key
                                                                                                            === (activeChildMenu
                                                                                                                && activeChildMenu.key) && 'active',
                                                                                                    )}
                                                                                                    onClick={() => handleClickChild(menuChild)}
                                                                                                    id={`sm_${menu.key}_${menuChild.key}`}
                                                                                                >
                                                                                                    <ListItemText
                                                                                                        className="itemText"
                                                                                                        primary={menuChild.label}
                                                                                                    />
                                                                                                </ListItem>
                                                                                            </a>
                                                                                        </Link>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                            {storeConfigDetailBatch[0] === '1' && (
                                                                                <>
                                                                                    {(menuChild.key === 'batchlist'
                                                                                        || menuChild.key === 'batchcreate'
                                                                                        || menuChild.key === 'batchpack') && (
                                                                                        <Link href={`${menuChild.url}`} key={menuChild.key}>
                                                                                            <a>
                                                                                                <ListItem
                                                                                                    button
                                                                                                    key={menuChild.key}
                                                                                                    className={clsx(
                                                                                                        classes.menuChildItem,
                                                                                                        menuChild.key
                                                                                                            === (activeChildMenu
                                                                                                                && activeChildMenu.key) && 'active',
                                                                                                    )}
                                                                                                    onClick={() => handleClickChild(menuChild)}
                                                                                                    id={`sm_${menu.key}_${menuChild.key}`}
                                                                                                >
                                                                                                    <ListItemText
                                                                                                        className="itemText"
                                                                                                        primary={menuChild.label}
                                                                                                    />
                                                                                                </ListItem>
                                                                                            </a>
                                                                                        </Link>
                                                                                    )}
                                                                                </>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                    {menu.key === 'configurations' && (
                                                                        <>
                                                                            {(menuChild.key === 'configurationtada' && storeConfigDetailTada[0] === '1') && (
                                                                                <Link href={`${menuChild.url}`} key={menuChild.key}>
                                                                                    <a>
                                                                                        <ListItem
                                                                                            button
                                                                                            key={menuChild.key}
                                                                                            className={clsx(
                                                                                                classes.menuChildItem,
                                                                                                menuChild.key
                                                                                                    === (activeChildMenu
                                                                                                        && activeChildMenu.key) && 'active',
                                                                                            )}
                                                                                            onClick={() => handleClickChild(menuChild)}
                                                                                            id={`sm_${menu.key}_${menuChild.key}`}
                                                                                        >
                                                                                            <ListItemText
                                                                                                className="itemText"
                                                                                                primary={menuChild.label}
                                                                                            />
                                                                                        </ListItem>
                                                                                    </a>
                                                                                </Link>
                                                                            )}
                                                                            {(menuChild.key === 'configurationvendorportal' && storeConfigDetailVendor[0] === '1') && (
                                                                                <Link href={`${menuChild.url}`} key={menuChild.key}>
                                                                                    <a>
                                                                                        <ListItem
                                                                                            button
                                                                                            key={menuChild.key}
                                                                                            className={clsx(
                                                                                                classes.menuChildItem,
                                                                                                menuChild.key
                                                                                                    === (activeChildMenu
                                                                                                        && activeChildMenu.key) && 'active',
                                                                                            )}
                                                                                            onClick={() => handleClickChild(menuChild)}
                                                                                            id={`sm_${menu.key}_${menuChild.key}`}
                                                                                        >
                                                                                            <ListItemText
                                                                                                className="itemText"
                                                                                                primary={menuChild.label}
                                                                                            />
                                                                                        </ListItem>
                                                                                    </a>
                                                                                </Link>
                                                                            )}
                                                                        </>
                                                                        )}

                                                                    {menu.key === 'vendor' && (
                                                                        <>
                                                                            {((menuChild.key === 'vendoririspayout' || menuChild.key === 'irispayoutapproval') && storeConfigBeneficiaries[0] === '0')

                                                                                ? null : (
                                                                                    <Link href={`${menuChild.url}`} key={menuChild.key}>
                                                                                        <a>
                                                                                            <ListItem
                                                                                                button
                                                                                                key={menuChild.key}
                                                                                                className={clsx(
                                                                                                    classes.menuChildItem,
                                                                                                    menuChild.key
                                                                                                    === (activeChildMenu
                                                                                                        && activeChildMenu.key) && 'active',
                                                                                                )}
                                                                                                onClick={() => handleClickChild(menuChild)}
                                                                                                id={`sm_${menu.key}_${menuChild.key}`}
                                                                                            >
                                                                                                <ListItemText
                                                                                                    className="itemText"
                                                                                                    primary={menuChild.label}
                                                                                                />
                                                                                            </ListItem>
                                                                                        </a>
                                                                                    </Link>
                                                                                )}
                                                                        </>
                                                                    )}
                                                                    {!(menu.key === 'pickpack' || menuChild.key === 'configurationtada' || menuChild.key === 'configurationvendorportal' || menu.key === 'vendor') && (
                                                                        <Link href={`${menuChild.url}`} key={menuChild.key}>
                                                                            <a>
                                                                                { menu.key === 'order' && (
                                                                                    <ListItem
                                                                                        button
                                                                                        key={menuChild.key}
                                                                                        className={clsx(
                                                                                            classes.menuChildItem,
                                                                                            menuChild.key === (activeChildMenu && activeChildMenu.key)
                                                                                                && 'active',
                                                                                        )}
                                                                                        onClick={() => handleClickChild(menuChild)}
                                                                                        id={`sm_${menu.key}_${menuChild.key}`}
                                                                                    >
                                                                                        <ListItemText
                                                                                            className="itemText"
                                                                                            primary={menuChild.label}
                                                                                        />
                                                                                            {(menuChild.key === 'orderqueue_allocating') && (
                                                                                                <ListItemSecondaryAction style={{ left: 'auto', right: '5%' }}>
                                                                                                    <Badge
                                                                                                        classes={{ badge: classes.customBadge }}
                                                                                                        max={1e6}
                                                                                                        badgeContent={
                                                                                                            counterMapping('allocation', orderCounter) > 0 ? formatNum(counterMapping('allocation', orderCounter)) : 0
                                                                                                        }
                                                                                                        invisible={!(open && counterMapping('allocation', orderCounter) > 0)}
                                                                                                    />
                                                                                                </ListItemSecondaryAction>
                                                                                            )}

                                                                                            {(menuChild.key === 'orderqueue_failed') && (
                                                                                                <ListItemSecondaryAction style={{ left: 'auto', right: '5%' }}>
                                                                                                    <Badge
                                                                                                        classes={{ badge: classes.customBadge }}
                                                                                                        max={1e6}
                                                                                                        badgeContent={
                                                                                                            counterMapping('failed', orderCounter) > 0 ? formatNum(counterMapping('failed', orderCounter)) : 0
                                                                                                        }
                                                                                                        invisible={!(open && counterMapping('failed', orderCounter) > 0)}
                                                                                                    />
                                                                                                </ListItemSecondaryAction>
                                                                                            )}

                                                                                            {(menuChild.key === 'orderqueue_order_processing') && (
                                                                                                <ListItemSecondaryAction style={{ left: 'auto', right: '5%' }}>
                                                                                                    <Badge
                                                                                                        classes={{ badge: classes.customBadge }}
                                                                                                        max={1e6}
                                                                                                        badgeContent={
                                                                                                            counterMapping('order_processing', orderCounter) > 0 ? formatNum(counterMapping('order_processing', orderCounter)) : 0
                                                                                                        }
                                                                                                        invisible={!(open && counterMapping('order_processing', orderCounter) > 0)}
                                                                                                    />
                                                                                                </ListItemSecondaryAction>
                                                                                            )}

                                                                                            {(menuChild.key === 'orderqueue_shipment_processing') && (
                                                                                                <ListItemSecondaryAction style={{ left: 'auto', right: '5%' }}>
                                                                                                    <Badge
                                                                                                        classes={{ badge: classes.customBadge }}
                                                                                                        max={1e6}
                                                                                                        badgeContent={
                                                                                                            counterMapping('shipment_processing', orderCounter) > 0 ? formatNum(counterMapping('shipment_processing', orderCounter)) : 0
                                                                                                        }
                                                                                                        invisible={!(open && counterMapping('shipment_processing', orderCounter) > 0)}
                                                                                                    />
                                                                                                </ListItemSecondaryAction>
                                                                                            )}

                                                                                            {(menuChild.key === 'orderqueue_complete') && (
                                                                                                <ListItemSecondaryAction style={{ left: 'auto', right: '5%' }}>
                                                                                                    <Badge
                                                                                                        classes={{ badge: classes.customBadge }}
                                                                                                        max={1e6}
                                                                                                        badgeContent={
                                                                                                            counterMapping('complete', orderCounter) > 0 ? formatNum(counterMapping('complete', orderCounter)) : 0
                                                                                                        }
                                                                                                        invisible={!(open && counterMapping('complete', orderCounter) > 0)}
                                                                                                    />
                                                                                                </ListItemSecondaryAction>
                                                                                            )}

                                                                                            {(menuChild.key === 'orderqueue_canceled') && (
                                                                                                <ListItemSecondaryAction style={{ left: 'auto', right: '5%' }}>
                                                                                                    <Badge
                                                                                                        classes={{ badge: classes.customBadge }}
                                                                                                        max={1e6}
                                                                                                        badgeContent={
                                                                                                            counterMapping('canceled', orderCounter) > 0 ? formatNum(counterMapping('canceled', orderCounter)) : 0
                                                                                                        }
                                                                                                        invisible={!(open && counterMapping('canceled', orderCounter) > 0)}
                                                                                                    />
                                                                                                </ListItemSecondaryAction>
                                                                                            )}
                                                                                    </ListItem>
                                                                                )}
                                                                                { menu.key !== 'order' && (
                                                                                    <ListItem
                                                                                        button
                                                                                        key={menuChild.key}
                                                                                        className={clsx(
                                                                                            classes.menuChildItem,
                                                                                            menuChild.key === (activeChildMenu && activeChildMenu.key)
                                                                                                && 'active',
                                                                                        )}
                                                                                        onClick={() => handleClickChild(menuChild)}
                                                                                        id={`sm_${menu.key}_${menuChild.key}`}
                                                                                    >
                                                                                        <ListItemText className="itemText" primary={menuChild.label} />
                                                                                    </ListItem>
                                                                                )}
                                                                            </a>
                                                                        </Link>
                                                                    )}
                                                                </>
                                                            )}
                                                    </div>
                                                ))}
                                            </List>
                                        </Collapse>
                                    )}
                                </div>
                            )}
                        </div>
                    ))}
            </List>
        </>
    );

    const SidebarMobile = () => (
        <Drawer
            style={{ zIndex: 1119 }}
            variant="temporary"
            open={open}
            onClose={() => setOpen(false)}
            className={clsx(classes.drawer, open ? classes.drawerOpen : classes.drawerClose)}
            classes={{
                paper: clsx(open ? classes.drawerOpen : classes.drawerClose),
            }}
            ModalProps={{ keepMounted: true }}
        >
            {SidebarContent()}
        </Drawer>
    );

    const SidebarDesktop = () => (
        <Drawer
            style={{ zIndex: 1119 }}
            variant="permanent"
            open={open}
            className={clsx(classes.drawer, open ? classes.drawerOpen : classes.drawerClose)}
            classes={{
                paper: clsx(open ? classes.drawerOpen : classes.drawerClose),
            }}
        >
            {SidebarContent()}
        </Drawer>
    );

    return (
        <>
            <Hidden smUp>{SidebarMobile()}</Hidden>
            <Hidden xsDown>{SidebarDesktop()}</Hidden>
        </>
    );
};

export default Sidebar;
