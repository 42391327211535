/* eslint-disable import/prefer-default-export */
export const optionsMasters = (t) => [
    {
        aclCode: 'oms_lite_header_oms',
        key: 'oms',
        label: t('menu:menuMaster:parent'),
        children: [
            {
                aclCode: 'oms_lite_company',
                key: 'company',
                label: t('menu:menuMaster:company'),
                url: '/oms/company',
            },
            {
                aclCode: 'oms_lite_channel',
                key: 'channel',
                label: t('menu:menuMaster:channel'),
                url: '/oms/channel',
            },
            {
                aclCode: 'oms_lite_virtual_stock',
                key: 'virtualstock',
                label: t('menu:menuMaster:virtualStock'),
                url: '/cataloginventory/virtualstock',
            },
            {
                aclCode: 'oms_lite_location',
                key: 'location',
                label: t('menu:menuMaster:location'),
                url: '/oms/location',
            },
            {
                aclCode: 'oms_lite_location_pickup',
                key: 'locationpickup',
                label: t('menu:menuMaster:locationPickup'),
                url: '/oms/locationpickup',
            },
            {
                aclCode: 'oms_lite_virtual_location',
                key: 'virtuallocationinventory',
                label: t('menu:menuMaster:virtualLocation'),
                url: '/cataloginventory/virtuallocationinventory',
            },
            {
                aclCode: 'location_zone',
                key: 'locationzone',
                label: t('menu:menuMaster:locationZone'),
                url: '/oms/locationzone',
            },
            {
                aclCode: 'priority_location_by_zone',
                key: 'prioritylocationbyzone',
                label: t('menu:menuMaster:priorityLocationByZone'),
                url: '/oms/prioritylocationbyzone',
            },
            {
                aclCode: 'oms_lite_priority_location_by_city',
                key: 'prioritylocationbycity',
                label: t('menu:menuMaster:priorityLocationByCity'),
                url: '/oms/prioritylocationbycity',
            },
        ],
    },
];
