import React from 'react';
import { useTranslation } from '@i18n';

import useAuthCustomer from '@root/src/hooks/useAuthCustomer';
import aclService from '@modules/theme/services/graphql';

const HelpChatCore = (props) => {
    const { Content } = props;

    const { t } = useTranslation(['chat']);

    const { loading, customer } = useAuthCustomer();
    const { loading: loadChatConfig, data: dataChatConfig } = aclService.isAccessAllowed({
        acl_code: 'oms_lite_swift_chat',
    });

    const isLoading = loading || loadChatConfig;
    const isChatEnabled = !!dataChatConfig?.isAccessAllowed;

    if (isLoading || !isChatEnabled || customer?.group?.code === 'Customer Service') {
        return <></>;
    }

    return <Content t={t} />;
};

export default HelpChatCore;
