import React from 'react';
import { useRouter } from 'next/router';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';

import useStyles from './style';

const { encrypt } = require('@helper_encryption');
const { encryptUrl } = require('@modules/sellerpromotion/pages/list/encryption');

// eslint-disable-next-line object-curly-newline
const FindAgentComponent = ({ t, loading, authUser, selectedAgent, fetchAgentList, onCloseWindow = () => null, onClose = () => null }) => {
    const router = useRouter();
    const styles = useStyles();

    React.useEffect(() => {
        if (authUser?.email && selectedAgent?.agent_code) {
            const redirectToChat = setTimeout(() => {
                const q = `${authUser.email}-${selectedAgent.agent_code}`;

                router.push(`/vendorportal/chat?ci=${encryptUrl(encrypt(JSON.stringify(q.toString())))}`);
                onCloseWindow();
            }, 1000);
            return () => clearTimeout(redirectToChat);
        }

        return () => null;
    }, [authUser?.email, selectedAgent?.agent_code]);

    return (
        <Card className={styles.root}>
            <CardHeader
                action={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <IconButton aria-label="close chat window" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                }
            />
            <CardContent classes={{ root: styles.content }}>
                {loading ? (
                    <Box>
                        {t('chat:findagent:messageLoading')}
                        <LinearProgress style={{ marginTop: 10 }} />
                    </Box>
                ) : (
                    <Box style={{ display: 'flex', flexDirection: 'column' }}>
                        {selectedAgent?.agent_code && selectedAgent?.agent_name ? (
                            <>
                                <Typography>{t('chat:findagent:messageSuccess')}</Typography>
                                <strong>{`[${selectedAgent.agent_code}] ${selectedAgent.agent_name}`}</strong>
                                <LinearProgress style={{ marginTop: 10 }} />
                            </>
                        ) : (
                            <>
                                {t('chat:findagent:messageFailed')}
                                <Button
                                    size="small"
                                    variant="contained"
                                    color="primary"
                                    onClick={() => fetchAgentList()}
                                    style={{ width: 'fit-content', margin: '10px auto' }}
                                >
                                    {t('chat:findagent:retry')}
                                </Button>
                            </>
                        )}
                    </Box>
                )}
            </CardContent>
        </Card>
    );
};

export default FindAgentComponent;
