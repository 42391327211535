import React from 'react';
import { useTranslation } from '@i18n';
import { makeStyles } from '@material-ui/core/styles';
import { COLOR_SWITCH } from '@theme_color';

const Footer = () => {
    const { t } = useTranslation(['common']);
    const color_text_footer = `${COLOR_SWITCH}`;
    const useStyles = makeStyles(() => ({
        divFooter: {
            color: color_text_footer,
            textAlign: 'center',
            padding: '20px 0',
            '& h3': {
                margin: 0,
                fontWeight: 400,
                fontSize: 14,
            },
        },
    }));
    const classes = useStyles();

    return (
        <div className={classes.divFooter}>
            <h3>{t('common:textFooter')}</h3>
        </div>
    );
};

export default Footer;
