import { graphqlEndpointMultiple } from '@root/swift.config.js';
import { getAppEnv } from '@helpers/env';

const appEnv = getAppEnv();
const token = (graphqlEndpointMultiple[appEnv])
    ? graphqlEndpointMultiple[`token_${appEnv}`] : graphqlEndpointMultiple.token_dev;

const contextMultple = {
    context: { request: graphqlEndpointMultiple.name, headers: { authorization: `Bearer ${token}` } },
};

export default contextMultple;
