/* eslint-disable react/jsx-wrap-multilines */
import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CloseIcon from '@material-ui/icons/Close';
import Fab from '@material-ui/core/Fab';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';

import FindAgent from '../../FindAgent';
import FindBuyer from '../../FindBuyer';
import useStyles from './style';

const HelpChatComponent = ({ t }) => {
    const styles = useStyles();

    const [showWindow, setShowWindow] = React.useState(false);
    const [showFindAgent, setShowFindAgent] = React.useState(false);
    const [showFindBuyer, setShowFindBuyer] = React.useState(false);

    const toggleWindow = () => {
        setShowWindow(!showWindow);
    };

    const toggleFindAgent = () => {
        setShowFindAgent(!showFindAgent);
    };

    const toggleFindBuyer = () => {
        setShowFindBuyer(!showFindBuyer);
    };

    return (
        <Box className={styles.root}>
            {!showWindow ? (
                <Fab variant="extended" color="primary" size="medium" onClick={toggleWindow}>
                    <Badge badgeContent={0} color="error" max={99}>
                        <img alt="logo-chat" src="/assets/img/icon_help_chat.svg" className="icon" />
                    </Badge>
                    Medbiz Chat
                </Fab>
            ) : (
                <>
                    <Card className={styles.dialogRoot}>
                        <CardHeader
                            className={styles.dialogHeader}
                            avatar={
                                <Avatar alt="Medbiz Logo" src="/assets/img/swiftpwa_192.png">
                                    MC
                                </Avatar>
                            }
                            title="Medbiz Chat"
                            subheader={
                                <Box>
                                    <FiberManualRecordIcon className={styles.online} />
                                    online
                                </Box>
                            }
                            action={
                                <IconButton aria-label="close dialog" onClick={toggleWindow}>
                                    <CloseIcon />
                                </IconButton>
                            }
                        />
                        <CardContent classes={{ root: styles.dialogContent }}>
                            <List
                                className={styles.listRoot}
                                subheader={<ListSubheader className={styles.listSubheader}>{t('chat:helpchat:optionTitle')}</ListSubheader>}
                                disablePadding
                            >
                                <ListItem key="start-with-buyer" className={styles.listItem} onClick={toggleFindBuyer} button divider>
                                    <ListItemText id="start-with-buyer" primary={t('chat:helpchat:optionChatWithBuyer')} />
                                </ListItem>
                                <ListItem key="start-with-cs" className={styles.listItem} onClick={toggleFindAgent} button divider>
                                    <ListItemText id="start-with-cs" primary={t('chat:helpchat:optionChatWithCS')} />
                                </ListItem>
                            </List>
                        </CardContent>
                    </Card>
                    {showFindAgent && <FindAgent onCloseWindow={toggleWindow} onClose={toggleFindAgent} />}
                    {showFindBuyer && <FindBuyer onCloseWindow={toggleWindow} onClose={toggleFindBuyer} />}
                </>
            )}
        </Box>
    );
};

export default HelpChatComponent;
