/* eslint-disable import/prefer-default-export */
export const optionsStatus = [
    { id: 0, name: 'Success' },
    { id: 1, name: 'Warning' },
    { id: 2, name: 'Error' },
];

export const optionsNotificationCategory = [
    {
        type: 'notif_error_order_queue',
        baseUrl: '/order/allorder/detail',
    },
    {
        type: 'notif_reallocation_order',
        baseUrl: '/order/allorder/detail',
    },
    {
        type: 'notif_new_order',
        baseUrl: '/order/orderconfirmation/detail',
    },
    {
        type: 'notif_rma',
        baseUrl: '/return/managerma/edit',
    },
    {
        type: 'notif_new_user',
        baseUrl: '/vendorportal/vendorusers/edit',
    },
    // {
    //     type: 'notif_uncategorized',
    //     baseUrl: '/oms/notification',
    // },
    {
        type: 'notif_courier',
        baseUrl: '/shipment/homedelivery/edit',
    },
    {
        type: 'notif_order_channel',
        baseUrl: '/order/allorder/detail',
    },
    // {
    //     type: 'notif_openapi',
    //     baseUrl: '/oms/notification',
    // },
    {
        type: 'notif_order_import',
        baseUrl: '/order/allorder/detail',
    },
    {
        type: 'notif_product',
        baseUrl: '/product/productlist/edit',
    },
    {
        type: 'notif_product_rejected',
        baseUrl: '/product/productlist/edit',
    },
    {
        type: 'notif_rating',
        baseUrl: '/vendorportal/rating/',
    },
];
