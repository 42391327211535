import { withTranslation } from '@i18n';
import { withApollo } from '@lib_apollo';

import Content from './components';
import Core from './core';

const FindBuyer = (props) => <Core Content={Content} {...props} />;

FindBuyer.getInitialProps = async () => ({ namespacesRequired: ['chat'] });
export default withApollo({ ssr: false })(withTranslation()(FindBuyer));
