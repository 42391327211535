import { useLazyQuery, useMutation } from '@apollo/client';
import * as Schema from '@modules/sellerpromotion/services/graphql/schema';
import { graphqlEndpointMultiple } from '@root/swift.config.js';
import { getAppEnv } from '@helpers/env';

const appEnv = getAppEnv();
const token = graphqlEndpointMultiple[appEnv] ? graphqlEndpointMultiple[`token_${appEnv}`] : graphqlEndpointMultiple.token_dev;

const context = {
    context: { request: graphqlEndpointMultiple.name, headers: { authorization: `Bearer ${token}` } },
};

const contextInternal = {
    context: { request: 'internal' },
};

const noCache = {
    fetchPolicy: 'no-cache',
};

const fetchPolicy = {
    fetchPolicy: 'cache-and-network',
};

export const getListCustomerGroups = (variables) => useLazyQuery(Schema.getListCustomerGroups, {
    variables,
    ...context,
    ...noCache,
});

export const getProductList = (options) => useLazyQuery(Schema.getProductList, {
    ...options,
    ...contextInternal,
    ...fetchPolicy,
    notifyOnNetworkStatusChange: true,
});

export const salesRuleGetList = (variables) => useLazyQuery(Schema.salesRuleGetList, {
    variables,
    ...context,
    ...fetchPolicy,
});

export const createSalesRule = (variables) => useMutation(Schema.createSalesRule, {
    variables,
    ...context,
});

export const updateSalesRule = (variables) => useMutation(Schema.updateSalesRule, {
    variables,
    ...context,
});

export const deleteCartPriceRuleCustom = (variables) => useMutation(Schema.deleteCartPriceRuleCustom, {
    variables,
    ...context,
});

export const getCartPriceRuleDetails = (options) => useLazyQuery(Schema.getCartPriceRuleDetails, {
    ...options,
    ...context,
    ...noCache,
});

export const getLocationList = (options) => useLazyQuery(Schema.getLocationList, {
    ...options,
    ...contextInternal,
});

export const getCustomerList = (variables) => useLazyQuery(Schema.getCustomerList, {
    variables,
    ...context,
    ...fetchPolicy,
    notifyOnNetworkStatusChange: true,
});

export const getSalesRuleCouponDetail = (options) => useLazyQuery(Schema.getSalesRuleCouponDetail, {
    ...options,
    ...context,
    ...noCache,
});

export const salesRuleExport = (variables) => useLazyQuery(Schema.salesRuleExport, {
    variables,
    ...context,
    ...noCache,
});

export const getBundlingPromoDetail = (options) => useLazyQuery(Schema.getBundlingPromoDetail, {
    ...options,
    ...context,
    ...noCache,
});

export const getBundlingPromoList = (variables) => useLazyQuery(Schema.getBundlingPromoList, {
    variables,
    ...context,
    ...fetchPolicy,
});

export const createBundlingPromo = (variables) => useMutation(Schema.createBundlingPromo, {
    variables,
    ...context,
});

export const updateBundlingPromo = (variables) => useMutation(Schema.updateBundlingPromo, {
    variables,
    ...context,
});

export default {
    salesRuleGetList,
    createSalesRule,
    updateSalesRule,
    deleteCartPriceRuleCustom,
    getCartPriceRuleDetails,
    getListCustomerGroups,
    getProductList,
    getLocationList,
    getCustomerList,
    getSalesRuleCouponDetail,
    salesRuleExport,
    getBundlingPromoDetail,
    getBundlingPromoList,
    updateBundlingPromo,
    createBundlingPromo,
};
