import { makeStyles } from '@material-ui/core/styles';

import { CYAN_BOLD, ORANGE_PRIMARY, WHITE } from '@theme_color';

const useStyles = makeStyles(() => ({
    root: {
        position: 'fixed',
        bottom: 16,
        right: 16,
        zIndex: 1,
        '& .MuiFab-root': {
            textTransform: 'capitalize',
            background: '#06AEC9',
        },
        '& .MuiBadge-root .icon': {
            marginRight: 10,
        },
    },
    online: {
        fontSize: '14px !important',
        color: '#1ae01a',
        verticalAlign: 'middle',
        marginRight: '3px',
        animation: '$ripple 1.2s infinite ease-in-out',
    },
    dialogRoot: {
        height: '80vh',
        width: '300px',
        borderRadius: '10px',
        background: 'white',
    },
    dialogHeader: {
        '&.MuiCardHeader-title': {
            fontSize: '1.2rem',
        },
    },
    dialogContent: {
        height: '100%',
    },
    listRoot: {
        border: `1px solid ${CYAN_BOLD}`,
        borderRadius: '0px 20px 20px 20px',
        overflow: 'hidden',
    },
    listSubheader: {
        background: CYAN_BOLD,
        '&.MuiListSubheader-root': {
            color: WHITE,
            fontSize: 14,
        },
    },
    listItem: {
        '&.MuiListItem-divider': {
            borderBottom: `1px solid ${CYAN_BOLD}`,
        },
        '&.MuiListItem-divider:last-child': {
            borderBottom: 'none',
        },
        '& .MuiListItemText-primary': {
            color: ORANGE_PRIMARY,
            fontSize: 14,
        },
    },
}));

export default useStyles;
