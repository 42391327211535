/* eslint-disable linebreak-style */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unknown-property */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import Badge from '@material-ui/core/Badge';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Hidden from '@material-ui/core/Hidden';
import CircularProgress from '@material-ui/core/CircularProgress';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DoneAll from '@material-ui/icons/DoneAll';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import loginGqlService from '@modules/login/services/graphql';
import gqlNotificationService from '@modules/notification/services/graphql';
import { removeIsLoginFlagging } from '@helper_auth';
import { useRouter } from 'next/router';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from '@i18n';
import { ORANGE_PRIMARY } from '@theme_color';
import ChatNotification from '@modules/theme/layout/components//chatNotification';
import LanguageSelect from '@modules/theme/layout/components/languageSelect';
import dateFormat from '@root/src/helpers/dateFormat';
import { optionsNotificationCategory } from '@modules/notification/helpers';
import aclService from '@modules/theme/services/graphql';
import useAuthCustomer from '@root/src/hooks/useAuthCustomer';
import { setCookies, getCookies } from '@helper_cookies';
import {
    COOKEY_CHAT_ENABLE,
    COOKEY_CHAT_CS_STATUS,
    updateCsChatStatus,
    updateFbUserStatus,
} from '@modules/vendorchat/helpers';

const StyledBadge = withStyles(() => ({
    badge: {
        right: 5,
        top: 3,
        padding: '0 4px',
        borderRadius: '8px',
        background: `${ORANGE_PRIMARY}`,
    },
}))(Badge);

const StyledNotifBadge = withStyles(() => ({
    badge: {
        color: '#fff',
        backgroundColor: `${ORANGE_PRIMARY}`,
        fontSize: '10px',
        padding: '0 5px',
        filter: 'opacity(0.8)',
    },
}))(Badge);

const RightToolbar = ({ notificationRes }) => {
    const { t } = useTranslation(['common', 'notification', 'user']);
    const router = useRouter();

    const [multiReadNotification] = gqlNotificationService.multiReadNotification();
    const [readAllNotification] = gqlNotificationService.readAllNotification();
    const [removeCustomerToken] = loginGqlService.removeToken();

    const { loading: loadingAuthUser, customer: authUser } = useAuthCustomer();
    const { loading: loadChatConfig, data: dataChatConfig } = aclService.isAccessAllowed({
        acl_code: 'oms_lite_swift_chat',
    });
    const [generateXSiteToken, { data: dataGenerateXSiteToken, loading: loadingGenerateXSiteToken }] = aclService.generateXSiteToken();

    const isLoading = loadingAuthUser || loadChatConfig;
    const isChatEnable = !!dataChatConfig?.isAccessAllowed;

    const handleLogout = () => {
        removeCustomerToken()
            .then(() => {
                if (isChatEnable) {
                    updateFbUserStatus(0);
                    updateCsChatStatus(0);
                }

                removeIsLoginFlagging();
                router.push('/login');
            })
            .catch(() => { });
    };
    const { loading, data } = notificationRes;
    const [loadmanual, setLoadmanual] = React.useState(false);

    const handleReadNotification = async (id) => {
        setLoadmanual(true);
        const variables = { id: [id] };

        try {
            await multiReadNotification({ variables }).then(() => {
                notificationRes.refetch();
                window.toastMessage({
                    open: true,
                    text: t('common:activity:success'),
                    variant: 'success',
                });
            });
        } catch (e) {
            window.toastMessage({
                open: true,
                text: t('common:activity:error'),
                variant: 'error',
            });
        }
        setLoadmanual(false);
    };

    const handleReadAllNotification = async () => {
        setLoadmanual(true);

        try {
            await readAllNotification().then(() => {
                notificationRes.refetch();
                window.toastMessage({
                    open: true,
                    text: t('notification:allNotificationWasRead'),
                    variant: 'success',
                });
            });
        } catch (e) {
            window.toastMessage({
                open: true,
                text: t('common:activity:error'),
                variant: 'error',
            });
        }
        setLoadmanual(false);
    };

    const notificationUrl = (type, targetId) => {
        const notificationCategory = optionsNotificationCategory;

        for (let x = 0; x < notificationCategory.length; x += 1) {
            if (type === notificationCategory[x].type) {
                const url = notificationCategory[x].baseUrl;
                if (targetId && url !== '/order/orderconfirmation/detail') {
                    return `${url}/${targetId}`;
                }
                return url.slice(0, url.lastIndexOf('/'));
            }
        }

        return '/oms/notification';
    };

    const handleRedirectReadNotification = async (id, url) => {
        setLoadmanual(true);
        const variables = { id: [id] };

        try {
            await multiReadNotification({ variables }).then(() => {
                notificationRes.refetch();
                window.toastMessage({
                    open: true,
                    text: t('common:activity:success'),
                    variant: 'success',
                });
                setTimeout(() => router.push(url), 250);
            });
        } catch (e) {
            window.toastMessage({
                open: true,
                text: t('common:activity:error'),
                variant: 'error',
            });
            setTimeout(() => router.push(url), 250);
        }
        setLoadmanual(false);
    };

    const validateURL = (url) => {
        const expression = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
        const pattern = new RegExp(expression);
        return !!url.match(pattern);
    };

    const handleRedirectAsBuyer = React.useCallback(async () => {
        try {
            await generateXSiteToken()
                .then(({ data: dataXSiteToken }) => {
                    if (!loadingGenerateXSiteToken && dataXSiteToken) {
                        if (validateURL(dataXSiteToken.generateXSiteToken)) {
                            window.open(`${dataXSiteToken.generateXSiteToken}`, '_blank');
                        } else {
                            window.backdropLoader(false);
                            window.toastMessage({
                                open: true,
                                text: t('common:error'),
                                variant: 'error',
                            });
                        }
                    }
                })
                .catch(() => {
                    window.backdropLoader(false);
                    window.toastMessage({
                        open: true,
                        text: t('common:error'),
                        variant: 'error',
                    });
                });
        } catch (error) {
            window.backdropLoader(false);
            window.toastMessage({
                open: true,
                text: t('common:error'),
                variant: 'error',
            });
        }
    }, [generateXSiteToken, loadingGenerateXSiteToken, dataGenerateXSiteToken]);

    React.useEffect(() => {
        const hasUpdatedCsChatStatus = !!getCookies(COOKEY_CHAT_CS_STATUS);
        if (!isLoading && isChatEnable && !hasUpdatedCsChatStatus) {
            updateCsChatStatus(1);
        }
    }, [isLoading, isChatEnable]);

    // hook for store status access module chat
    React.useEffect(() => {
        if (!loadChatConfig && dataChatConfig?.isAccessAllowed !== null) {
            setCookies(COOKEY_CHAT_ENABLE, dataChatConfig.isAccessAllowed);
        }
    }, [loadChatConfig, dataChatConfig?.isAccessAllowed]);

    return (
        <ul>
            <li>
                <LanguageSelect white />
            </li>
            <li>
                <a href="#">
                    <Hidden xsDown implementation="css">
                        {!loadingAuthUser && authUser?.firstname ? `${authUser?.firstname} ${authUser?.lastname ?? ''}` : 'loading...'}
                        <KeyboardArrowDownIcon id="svg_akun_saya" style={{ verticalAlign: 'middle', marginLeft: 5 }} />
                    </Hidden>
                    <Hidden smUp implementation="css">
                        <AccountCircleOutlinedIcon id="svg_akun_saya_mobile" style={{ transform: 'translateY(2px)', fontSize: 27 }} />
                    </Hidden>
                </a>
                <ul>
                    <li>
                        <a onClick={handleRedirectAsBuyer} disabled={loadingGenerateXSiteToken} style={{ cursor: 'pointer' }} id="button_buyer" className="linkOut">
                            {loadingGenerateXSiteToken ? (
                                <span style={{ color: 'gray', opacity: 0.8 }}>
                                    {t('common:loading')}
                                    ...
                                </span>
                            ) : t('common:loginAsBuyer')}
                        </a>
                    </li>
                    <li>
                        <a id="button_akun_saya" className="linkOut" href="/useredit">
                            {t('common:myAccount')}
                        </a>
                    </li>
                    <li>
                        <a id="button_keluar" className="linkOut" href="#" onClick={handleLogout}>
                            {t('common:signOut')}
                        </a>
                    </li>
                </ul>
            </li>
            {!isLoading && isChatEnable && (
                <li>
                    <ChatNotification customer={authUser} updateCustomerStatus={updateFbUserStatus} />
                </li>
            )}
            <li>
                <IconButton style={{ padding: 0 }} size="small" color="inherit">
                    <StyledBadge badgeContent={data?.getNotificationList.total_count} color="secondary">
                        <img style={{ filter: 'brightness(0%) invert(100%)' }} alt="" src="/assets/img/layout/notification.svg" />
                    </StyledBadge>
                </IconButton>
                <ul style={{ width: 290 }}>
                    <div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                padding: '0 10px',
                                marginTop: '5px',
                                marginBottom: '10px',
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <span style={{ fontSize: '18px', color: 'black', fontWeight: 700 }}>{t('user:notifications')}</span>
                                {loading || loadmanual ? (
                                    ''
                                ) : data?.getNotificationList?.items?.length > 0 ? (
                                    <StyledNotifBadge badgeContent={data?.getNotificationList.total_count || 0} style={{ marginLeft: '20px' }} />
                                ) : (
                                    ''
                                )}
                            </div>

                            <Tooltip title={t('notification:markAllAsRead')} arrow>
                                <IconButton
                                    size="small"
                                    style={{ padding: 0 }}
                                    onClick={() => handleReadAllNotification()}
                                    disabled={!!(loading || loadmanual || data?.getNotificationList?.items?.length < 1)}
                                >
                                    <DoneAll
                                        style={{
                                            fontSize: '18px',
                                            color:
                                                loading || loadmanual || data?.getNotificationList?.items?.length < 1 ? 'black' : `${ORANGE_PRIMARY}`,
                                        }}
                                    />
                                </IconButton>
                            </Tooltip>
                        </div>

                        {loading || loadmanual ? (
                            <div
                                style={{
                                    margin: '0 10px',
                                    minHeight: '258px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                }}
                            >
                                <CircularProgress size={40} />
                            </div>
                        ) : data?.getNotificationList?.items?.length < 1 ? (
                            <li
                                style={{
                                    color: 'black',
                                    margin: 0,
                                    width: 270,
                                    textOverflow: 'ellipsis',
                                    display: 'flex',
                                    justifyItems: 'center',
                                    alignItems: 'center',
                                    paddingTop: '10px',
                                    paddingBottom: '10px',
                                }}
                            >
                                <span
                                    style={{
                                        color: '#435179',
                                        fontSize: '12px',
                                        fontWeight: 'bold',
                                        marginLeft: 'auto',
                                        marginRight: 'auto',
                                    }}
                                >
                                    {t('notification:allNotificationWasRead')}
                                </span>
                            </li>
                        ) : (
                            data?.getNotificationList?.items?.map((notif, idx) => (
                                <li
                                    key={idx}
                                    style={{
                                        color: 'black',
                                        margin: 0,
                                        width: 270,
                                        textOverflow: 'ellipsis',
                                        display: 'flex',
                                        justifyItems: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div style={{ flexGrow: 1 }}>
                                        <span style={{ color: '#B1BCDB', fontSize: '12px' }}>{dateFormat(notif.created_at)}</span>
                                        <br />

                                        <div
                                            onClick={() => handleRedirectReadNotification(notif.id, notificationUrl(notif.category, notif.target_id))}
                                        >
                                            <span
                                                style={{
                                                    color: '#435179',
                                                    fontSize: '14px',
                                                    fontWeight: 700,
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                {notif.entity_type}
                                            </span>
                                            <br />
                                            <Tooltip title={notif.message} arrow>
                                                <span
                                                    style={{
                                                        color: '#435179',
                                                        fontSize: '12px',
                                                        overflow: 'hidden',
                                                        cursor: 'pointer',
                                                    }}
                                                >
                                                    {notif.message.slice(0, 30)}
                                                    ...
                                                </span>
                                            </Tooltip>
                                        </div>
                                    </div>

                                    <Tooltip title={t('notification:markAsRead')} arrow>
                                        <Clear
                                            style={{
                                                color: '#B1BCDB',
                                                fontSize: '18px',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => handleReadNotification(notif.id)}
                                        />
                                    </Tooltip>
                                </li>
                            ))
                        )}

                        <div style={{ margin: '0 10px', height: '1px', backgroundColor: '#B1BCDB' }} />

                        <div
                            style={{
                                margin: '10px 10px 5px 10px',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                gap: '4px',
                            }}
                        >
                            <a
                                href="/oms/notification"
                                style={{
                                    color: `${ORANGE_PRIMARY}`,
                                    fontWeight: 700,
                                    fontSize: '12px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                {t('notification:viewAll')}
                                <ChevronRight style={{ fontSize: '14px' }} />
                            </a>
                        </div>
                    </div>
                </ul>
            </li>

            <style jsx>
                {`
                    ul {
                        margin: 0;
                        list-style: none;
                        padding: 0;
                        float: right;
                        font-size: 10px;
                        font-family: Montserrat !important;
                        position: fixed;
                        right: 12px;
                    }
                    li {
                        display: inline-block;
                        padding: 5px 12px;
                        position: relative;
                        vertical-align: middle;
                    }
                    li:hover > ul {
                        display: block;
                    }
                    ul ul {
                        position: absolute;
                        display: none;
                        margin: 0;
                        padding: 5px 10px;
                        z-index: 999;
                        background: #fff;
                        box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
                        right: 0;
                    }
                    ul ul li {
                        display: block;
                    }
                    ul ul ul {
                        position: absolute;
                        top: 0;
                        left: 100%;
                    }
                    a {
                        color: #ffffff;
                        text-decoration: none;
                        white-space: nowrap;
                        font-size: 14px;
                    }
                    a:hover {
                        border-bottom: 1px dashed #fff;
                    }
                    .linkOut {
                        color: ${ORANGE_PRIMARY};
                    }
                    .viewMessage {
                        color: ${ORANGE_PRIMARY};
                        font-size: 10px;
                    }
                `}
            </style>
        </ul>
    );
};
export default RightToolbar;
