/* eslint-disable import/prefer-default-export */
export const optionsVendor = (t) => [
    {
        aclCode: 'oms_lite_header_vendor_portal',
        key: 'vendor',
        label: t('menu:menuVendor:parent'),
        children: [
            {
                aclCode: 'oms_lite_vendor_request',
                key: 'requestvendor',
                label: t('menu:menuVendor:manageVendorRequest'),
                url: '/vendorportal/requestvendor',
            },
            {
                aclCode: 'oms_lite_vendor_manage',
                key: 'managevendor',
                label: t('menu:menuVendor:manageVendor'),
                url: '/vendorportal/managevendor',
            },
            {
                aclCode: 'oms_lite_vendor_customer_address_verification',
                key: 'addressverification',
                label: t('menu:menuVendor:addressVerification'),
                url: '/vendorportal/addressverification',
            },
            {
                aclCode: 'vendor_bulk_tools',
                key: 'vendorbulktools',
                label: t('menu:menuVendor:productBulkTools'),
                url: '/vendorportal/bulktools',
            },
            {
                aclCode: 'oms_lite_vendor_product_approval',
                key: 'productapproval',
                label: t('menu:menuVendor:productApproval'),
                url: '/vendorportal/productapproval',
            },
            {
                aclCode: 'vendor_manage_promotion',
                key: 'managepromotion',
                label: t('menu:menuVendor:managePromotion'),
                url: '/vendorportal/managepromotion',
            },
            {
                aclCode: 'vendor_manage_iris',
                key: 'vendoririspayout',
                label: t('menu:menuVendor:irisPayout'),
                url: '/vendorportal/vendoririspayout',
            },
            {
                aclCode: 'oms_lite_vendor_iris',
                key: 'irispayoutapproval',
                label: t('menu:menuVendor:irisPayoutApproval'),
                url: '/vendorportal/irispayoutapproval',
            },
            {
                aclCode: 'oms_lite_vendor_iris',
                key: 'vendoririsstatements',
                label: t('menu:menuVendor:irisStatements'),
                url: '/vendorportal/irisstatements',
            },
            {
                aclCode: 'oms_lite_vendor_payout',
                key: 'vendorpayout',
                label: t('menu:menuVendor:vendorPayout'),
                url: '/vendorportal/vendorpayout',
            },
            {
                aclCode: 'oms_lite_vendor_document_verification',
                key: 'documentverification',
                label: t('menu:menuVendor:documentVerification'),
                url: '/vendorportal/documentverification',
            },
            {
                aclCode: 'oms_lite_vendor_document_history',
                key: 'documenthistory',
                label: t('menu:menuVendor:documentHistory'),
                url: '/vendorportal/documenthistory',
            },
            {
                aclCode: 'oms_lite_vendor_document_upload',
                key: 'documentupload',
                label: t('menu:menuVendor:documentUpload'),
                url: '/vendorportal/documentupload',
            },
            {
                aclCode: 'oms_lite_vendor_manage_cee',
                key: 'managecee',
                label: t('menu:menuVendor:manageCee'),
                url: '/vendorportal/managecee',
            },
            {
                aclCode: 'oms_lite_vendor_salesman_reports' || 'oms_lite_vendor_cee_report_buyer',
                key: 'ceereport',
                label: t('menu:menuVendor:ceeReport'),
                url: '/vendorportal/ceereport',
            },
            {
                aclCode: 'oms_lite_vendor_manage_credit_limit',
                key: 'managecreditregular',
                label: t('menu:menuVendor:manageCreditLimit'),
                url: '/vendorportal/managecreditregular',
            },
            {
                aclCode: 'oms_lite_vendor_help_desk',
                key: 'helpdesk',
                label: t('menu:menuVendor:helpDesk'),
                url: '/vendorportal/helpdesk',
            },
            {
                aclCode: 'oms_lite_vendor_users',
                key: 'vendorusers',
                label: t('menu:menuVendor:allUsers'),
                url: '/vendorportal/vendorusers',
            },
            {
                aclCode: 'oms_lite_vendor_user_account_migration',
                key: 'usermigration',
                label: t('menu:menuVendor:userMigration'),
                url: '/vendorportal/usermigration',
            },
            {
                aclCode: 'oms_lite_customer_request',
                key: 'customerrequest',
                label: t('menu:menuVendor:customerRequest'),
                url: '/vendorportal/customerrequest',
            },
            {
                aclCode: 'vendor_cart_rules',
                key: 'sellerpromotion',
                label: t('menu:menuVendor:sellerPromotion'),
                url: '/vendorportal/sellerpromotion',
            },
            {
                aclCode: 'oms_lite_vendor_seller_review',
                key: 'vendorrating',
                label: t('menu:menuVendor:rating'),
                url: '/vendorportal/rating',
            },
            {
                aclCode: 'vendor_payment_shipping_method',
                key: 'paymentshippingmethod',
                label: t('menu:menuVendor:shippingMethod'),
                url: '/vendorportal/paymentshippingmethod',
            },
            {
                aclCode: 'oms_lite_vendor_fine_management',
                key: 'finemanagement',
                label: t('menu:menuVendor:fineManagement'),
                url: '/vendorportal/finemanagement',
            },
            {
                aclCode: 'oms_lite_platform_revenue',
                key: 'platformrevenue',
                label: t('menu:menuVendor:platformrevenue'),
                url: '/vendorportal/platformrevenue',
            },
            {
                aclCode: 'oms_lite_bri_fund_transfer',
                key: 'brifundtransfer',
                label: t('menu:menuVendor:brifundtransfer'),
                url: '/vendorportal/brifundtransfer',
            },
            {
                aclCode: 'oms_lite_bri_fund_transfer',
                key: 'britf',
                label: t('menu:menuVendor:briDf'),
                url: '/vendorportal/bridf',
            },
        ],
    },
];
