import { makeStyles } from '@material-ui/core/styles';

import { ORANGE_PRIMARY, WHITE, COLOR_SWITCH } from '@theme_color';

const useStyles = makeStyles((theme) => ({
    root: {
        position: 'absolute',
        bottom: 0,
        zIndex: 1,
        height: 'calc(100vh - 96px)',
        width: '300px',
        borderRadius: '10px',
        background: 'white',
    },
    header: {
        '& .MuiCardHeader-title': {
            fontWeight: 'bold',
        },
        '& .MuiCardHeader-title, .MuiCardHeader-subheader': {
            fontSize: 14,
        },
    },
    content: {
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
    },
    listRoot: {
        maxHeight: 'calc(100vh - 168px)',
        overflow: 'auto',
        paddingBottom: 20,
    },
    listItem: {
        '&.MuiListItem-secondaryAction': {
            paddingRight: 56,
        },
        '& .MuiListItemText-primary': {
            fontSize: 14,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
        '& .MuiListItemText-secondary': {
            fontSize: 14,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
    },
    listItemAction: {
        borderRadius: 50,
        background: `${ORANGE_PRIMARY} !important`,
        '&.MuiIconButton-root': {
            padding: 8,
        },
        '& .MuiSvgIcon-root': {
            fill: WHITE,
        },
    },
    formUserSearch: {
        display: 'flex',
        padding: '10px 15px',
        borderBottom: '1px solid #E8EDF1',
    },
    searchInput: {
        flex: 1,
        width: '100%',
        marginRight: '10px !important',
        '&:hover': {
            '& .MuiInput-underline:before, .MuiInput-underline:after': {
                borderBottom: 'none !important',
            },
        },
        '& .MuiInput-underline:before, .MuiInput-underline:after': {
            borderBottom: 'none !important',
        },
        '& .MuiInput-root': {
            backgroundColor: WHITE,
            borderRadius: '50px !important',
            padding: '5px 10px',
            border: `1px solid ${COLOR_SWITCH}`,
        },
        '& .MuiInputBase-input': {
            paddingLeft: 16,
        },
        '& .MuiInputBase-input::placeholder': {
            fontSize: '14px',
            opacity: 1,
            color: COLOR_SWITCH,
        },
        '&.message .MuiInput-root': {
            borderRadius: '50px !important',
            padding: '5px 5px 8px',
        },
        '& .MuiSvgIcon-root': {
            fill: COLOR_SWITCH,
        },
        [theme.breakpoints.down('md')]: {
            marginRight: '0px !important',
        },
    },
}));

export default useStyles;
