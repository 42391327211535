/* eslint-disable max-len */
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import tz from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(tz);

const dateFormat = (date = new Date(), format = 'DD-MM-YYYY H:mm:ss') => dayjs(date).format(format);
export const dateFormatTimeZone = (date = new Date(), timezone = 'America/New_York', format = 'DD-MM-YYYY H:mm:ss') => dayjs(date).tz(timezone).format(format);

export default dateFormat;
