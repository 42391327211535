/* eslint-disable import/prefer-default-export */
const GROUP_DONT_HAVE_DASHBOARDS = [
    {
        code: 'CEE',
        routeIndex: '/vendorportal/ceereport',
    },
    {
        code: 'Customer Service',
        routeIndex: '/vendorportal/chat',
    },
    {
        code: 'Tax',
        routeIndex: '/order/tax',
    },
];

export const getGroupDontHaveDashboard = (authCustomerGroupCode) => GROUP_DONT_HAVE_DASHBOARDS.find(({ code }) => code === authCustomerGroupCode);
