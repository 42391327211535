import React from 'react';
import { useRouter } from 'next/router';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import SendIcon from '@material-ui/icons/Send';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { Waypoint } from 'react-waypoint';
import TextField from '@common_textfield';

import useStyles from './style';

const { encrypt } = require('@helper_encryption');
const { encryptUrl } = require('@modules/sellerpromotion/pages/list/encryption');

const FindBuyerComponent = ({
    t,
    loading,
    loadingStartChat,
    authUser,
    buyerList,
    buyerTotal,
    startChat,
    selectedBuyer,
    fetchMore,
    changeSerchUser,
    handleSearchUser,
    searchText,
    closeSearch,
    handleClearSearch,
    onCloseWindow = () => null,
    onClose = () => null,
}) => {
    const router = useRouter();
    const styles = useStyles();

    React.useEffect(() => {
        if (selectedBuyer?.customer_email && authUser?.chat_agent_code) {
            const redirectToChat = setTimeout(() => {
                const q = `${selectedBuyer.customer_email}-${authUser.chat_agent_code}`;

                router.push(`/vendorportal/chat?ci=${encryptUrl(encrypt(JSON.stringify(q.toString())))}`);
                onCloseWindow();
            }, 2000);
            return () => clearTimeout(redirectToChat);
        }

        return () => null;
    }, [selectedBuyer?.customer_email, authUser?.chat_agent_code]);

    const pageSize = buyerList.length < buyerTotal ? buyerList.length + 10 : buyerTotal;
    const handleFetchMore = async () => {
        await fetchMore({
            variables: {
                pageSize,
                currentPage: 1,
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
                if (!fetchMoreResult) return previousResult;

                return {
                    ...fetchMoreResult,
                };
            },
        });
    };

    return (
        <Card className={styles.root}>
            <CardHeader
                className={styles.header}
                title={t('chat:findbuyer:dialogTitle')}
                subheader={`${buyerTotal} ${t('chat:buyer')}`}
                action={
                    // eslint-disable-next-line react/jsx-wrap-multilines
                    <IconButton aria-label="close chat window" onClick={onClose}>
                        <CloseIcon />
                    </IconButton>
                }
            />
            {loading && buyerList.length < 1 && (
                <CardContent classes={{ root: styles.content }}>
                    <Box>
                        {t('chat:findbuyer:messageLoading')}
                        <LinearProgress style={{ marginTop: 10 }} />
                    </Box>
                </CardContent>
            )}
            {!loadingStartChat && (
                <form className={styles.formUserSearch} onSubmit={handleSearchUser}>
                    <TextField
                        name="search"
                        placeholder={t('chat:searchplaceholder')}
                        className={styles.searchInput}
                        inputProps={{
                            autocomplete: 'off',
                        }}
                        disabled={loading || closeSearch}
                        value={searchText}
                        onChange={changeSerchUser}
                        // eslint-disable-next-line react/jsx-no-duplicate-props
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    {!closeSearch ? (
                                        <IconButton type="submit" disabled={!searchText}>
                                            <SearchIcon />
                                        </IconButton>
                                    ) : (
                                        <IconButton type="button" onClick={handleClearSearch}>
                                            <CloseIcon />
                                        </IconButton>
                                    )}
                                </InputAdornment>
                            ),
                        }}
                    />
                </form>
            )}
            {loadingStartChat && (
                <CardContent classes={{ root: styles.content }}>
                    <Box>
                        <Typography>{t('chat:findagent:messageSuccess')}</Typography>
                        <strong>{selectedBuyer.company_name}</strong>
                        <LinearProgress style={{ marginTop: 10 }} />
                    </Box>
                </CardContent>
            )}
            {!loading && !loadingStartChat && buyerTotal === 0 && (
                <CardContent classes={{ root: styles.content }} style={{ marginTop: -100 }}>
                    <Box>
                        {t('chat:findbuyer:noBuyer')}
                    </Box>
                </CardContent>
            )}
            {!loadingStartChat && (
                <List className={styles.listRoot} disablePadding>
                    {buyerList.map((buyer) => (
                        <>
                            <ListItem key={buyer.customer_email} className={styles.listItem} button divider>
                                <Tooltip
                                    title={
                                        // eslint-disable-next-line react/jsx-wrap-multilines
                                        <>
                                            <b>{buyer.company_name}</b>
                                            <br />
                                            {buyer.customer_email}
                                        </>
                                    }
                                >
                                    <ListItemText id={buyer.customer_email} primary={buyer.company_name} secondary={buyer.customer_email} />
                                </Tooltip>
                                <ListItemSecondaryAction>
                                    <IconButton className={styles.listItemAction} onClick={() => startChat(buyer)}>
                                        <SendIcon color="inherit" fontSize="small" />
                                    </IconButton>
                                </ListItemSecondaryAction>
                            </ListItem>
                            <Waypoint onLeave={() => handleFetchMore()} />
                        </>
                    ))}
                    {loading && buyerList.length > 0 && (
                        <CardContent classes={{ root: styles.content }} style={{ marginBottom: 50 }}>
                            <Box>
                                {t('chat:findbuyer:messageLoading')}
                                <LinearProgress style={{ marginTop: 10 }} />
                            </Box>
                        </CardContent>
                    )}
                </List>
            )}
        </Card>
    );
};

export default FindBuyerComponent;
