import React from 'react';
import { useTranslation } from '@i18n';

import useAuthCustomer from '@root/src/hooks/useAuthCustomer';
import { addActiveSession, createFirebaseDoc, getAgentList } from '../../services/graphql/index';

const FindAgentCore = ({ Content, onClose, ...restProps }) => {
    const { t } = useTranslation(['chat']);

    const { loading: loadingAuthUser, customer: authUser } = useAuthCustomer();
    const {
        data,
        loading,
        error,
        refetch: fetchAgentList,
    } = getAgentList({
        variables: { role: 'cs' },
        notifyOnNetworkStatusChange: true,
    });

    const [createSession, { loading: loadingCreateSession }] = addActiveSession();
    const [createFbDoc, { loading: loadingCreateFbDoc }] = createFirebaseDoc();

    const [selectedAgent, setSelectedAgent] = React.useState({});

    const isLoading = loadingAuthUser || loading || loadingCreateFbDoc || loadingCreateSession;

    const getRandomAgent = () => {
        const randNum = Math.floor(Math.random() * data.getAgentList.length);
        const agent = data.getAgentList[randNum];

        return agent;
    };

    const startNewChat = async () => {
        const agent = getRandomAgent();
        if (!agent) return;

        const { agent_code, agent_name } = agent;
        const { firstname, lastname, email } = authUser;

        await createFbDoc({
            variables: {
                agent_code,
                agent_name,
                customer_email: email,
                customer_name: `${firstname} ${lastname}`,
            },
        });

        const { data: dataActiveSession } = await createSession({
            variables: { agent_code, customer_email: email },
        });

        if (dataActiveSession.addActiveSession) {
            setSelectedAgent(agent);
        }
    };

    React.useEffect(() => {
        if (data?.getAgentList.length > 0) startNewChat();
    }, [data]);

    React.useEffect(() => {
        if (error) {
            window.toastMessage({
                open: true,
                variant: 'error',
                text: 'Failed to get customer service',
            });
        }
    }, [error]);

    return (
        <Content
            t={t}
            loading={isLoading}
            authUser={authUser}
            selectedAgent={selectedAgent}
            fetchAgentList={fetchAgentList}
            onClose={onClose}
            {...restProps}
        />
    );
};

export default FindAgentCore;
