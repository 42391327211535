import { withTranslation } from '@i18n';
import { withApollo } from '@lib_apollo';

import Content from './components/index';
import Core from './core';

const HelpChat = (props) => <Core Content={Content} {...props} />;

HelpChat.getInitialProps = async () => ({ namespacesRequired: ['chat'] });
export default withApollo({ ssr: false })(withTranslation()(HelpChat));
