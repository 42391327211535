/* eslint-disable import/prefer-default-export */
export const optionsOrder = (t) => [
    {
        aclCode: 'oms_lite_header_sales',
        key: 'order',
        label: t('menu:menuOrder:parent'),
        children: [
            {
                aclCode: 'oms_lite_sales_order_queue',
                key: 'orderqueue_all',
                label: t('menu:menuOrder:allOrders'),
                url: '/order/allorder',
            },
            {
                aclCode: 'order_allocating',
                key: 'orderqueue_allocating',
                label: t('menu:menuOrder:allocating'),
                url: '/order/allocating',
            },
            {
                aclCode: 'order_failed',
                key: 'orderqueue_failed',
                label: t('menu:menuOrder:failed'),
                url: '/order/failed',
            },
            {
                aclCode: 'order_processing',
                key: 'orderqueue_order_processing',
                label: t('menu:menuOrder:orderProcessing'),
                url: '/order/order_processing',
            },
            {
                aclCode: 'order_shipment_processing',
                key: 'orderqueue_shipment_processing',
                label: t('menu:menuOrder:shipmentProcessing'),
                url: '/order/shipment_processing',
            },
            {
                aclCode: 'order_completed',
                key: 'orderqueue_complete',
                label: t('menu:menuOrder:completed'),
                url: '/order/complete',
            },
            {
                aclCode: 'order_canceled',
                key: 'orderqueue_canceled',
                label: t('menu:menuOrder:canceled'),
                url: '/order/canceled',
            },
            {
                aclCode: 'sales_order_invoice',
                key: 'orderqueue_invoice',
                label: t('menu:menuOrder:invoice'),
                url: '/order/invoice',
            },
            {
                aclCode: 'sales_order_queue_bulk_tools',
                key: 'orderqueue_bulk_tools',
                label: t('menu:menuOrder:bulkTools'),
                url: '/order/bulktools',
            },
            {
                aclCode: 'export_order',
                key: 'exportorder',
                label: t('menu:menuOrder:exportOrder'),
                url: '/order/exportorder',
            },
            {
                aclCode: 'oms_lite_vendor_quotation',
                key: 'quotation_stock',
                label: t('menu:menuOrder:stockQuotation'),
                url: '/order/quotationstock',
            },
            {
                aclCode: 'oms_lite_vendor_quotation',
                key: 'quotation',
                label: t('menu:menuOrder:biddingAndCPQ'),
                url: '/order/quotation',
            },
            {
                aclCode: 'shipment_delivery_faktur_pajak',
                key: 'tax',
                label: t('menu:menuOrder:tax'),
                url: '/order/tax',
            },
            {
                aclCode: 'oms_lite_overdue_order',
                key: 'overdue',
                label: t('menu:menuOrder:overdue'),
                url: '/order/overdue',
            },
            {
                aclCode: 'oms_lite_order_confirmation',
                key: 'order_confirmation',
                label: t('menu:menuOrder:orderConfirmation'),
                url: '/order/orderconfirmation',
            },
            // { aclCode: 'orderreallocation', label: 'Order Reallocation', url: '/sales/orderreallocation' },
        ],
    },
];
