/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
/* eslint-disable no-script-url */
/* eslint-disable no-new */
/* eslint-disable react/no-unknown-property */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react';
import Link from 'next/link';
import { useTranslation } from '@i18n';
import Box from '@material-ui/core/Box';
import Badge from '@material-ui/core/Badge';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

import firebaseApp from '@lib_firebase/index';
import { PRIMARY, GRAY_LIGHT, ORANGE_PRIMARY } from '@theme_color';
import { ChevronRight } from '@material-ui/icons/index';

import { notifyLocalUnreadMsgs } from '@modules/vendorchat/helpers/index';

const useStyles = makeStyles((theme) => ({
    container: {
        position: 'relative',
        '& ul': {
            display: 'none',
            position: 'absolute',
            top: 35,
            right: 0,
            width: 270,
            background: '#fff',
            margin: 0,
            padding: '12px 9px',
            listStyle: 'none',
            fontSize: 10,
            border: `1px solid ${GRAY_LIGHT}`,
            boxShadow: '0 5px 10px rgba(0, 0, 0, 0.15)',
        },
        '& ul li': {
            position: 'relative',
            display: 'inline-block',
            verticalAlign: 'middle',
            color: PRIMARY,
            fontSize: 12,
        },
        '& li.header': {
            display: 'flex',
            alignItems: 'center',
            paddingBottom: 2,
            '& span': {
                fontSize: 18,
                color: 'black',
                fontWeight: 700,
            },
            '& .MuiBadge-root .MuiBadge-badge': {
                color: '#fff !important',
                fontSize: 10,
            },
        },
        '& li.viewAll': {
            marginTop: '7px',
            display: 'flex',
            justifyContent: 'flex-start',
        },
        '&:hover > ul': {
            display: 'block',
        },
    },
    iconButton: {
        height: 46,
        width: 46,
        '& .icon': {
            height: 24,
            width: 24,
        },
        [theme.breakpoints.down('xs')]: {
            height: 30,
            width: 30,
        },
    },
}));

const StyledBadge = withStyles(() => ({
    badge: {
        right: 5,
        top: 3,
        padding: '0 4px',
        borderRadius: '8px',
        background: `${ORANGE_PRIMARY}`,
        color: '#fff',
    },
}))(Badge);

const ChatNotification = ({ customer, updateCustomerStatus }) => {
    const { t } = useTranslation(['common']);
    const classes = useStyles();

    const db = firebaseApp.firestore();

    const [agentMsgs, setAgentMsgs] = useState([]);
    const [custMsgs, setCustMsgs] = useState([]);

    /**
     * intermitten, only triggering when user have do something (input form, click button, etc)
     */
    React.useEffect(() => {
        const handleUserOffline = () => {
            // set firebase user as offline
            updateCustomerStatus(0);
        };
        window.addEventListener('beforeunload', handleUserOffline);
        return () => window.removeEventListener('beforeunload', handleUserOffline);
    }, []);

    useEffect(() => {
        if (Object.keys(customer).length && customer?.chat_agent_code) {
            updateCustomerStatus();
        }
    }, [customer?.chat_agent_code, customer?.email]);

    // unread messages from firebase
    useEffect(() => {
        if (Object.keys(customer).length && customer?.chat_agent_code) {
            const refereceUserDb = db.collection('messages');

            const queryByAgentCode = refereceUserDb.where('is_admin_read', 'in', ['0', 0]).where('agent_code', '==', customer.chat_agent_code);
            const unsubQueryByAgentCode = queryByAgentCode.onSnapshot((querySnapshot) => {
                const unreads = [];
                querySnapshot.docs.forEach((doc) => {
                    unreads.push({ chatId: doc.id, ...doc.data() });
                });
                setAgentMsgs(unreads);
            });

            let unsubQueryByCustEmail = () => null;
            // possible auth user as customer
            if (customer?.group?.code !== 'Customer Service') {
                const queryByCustEmail = refereceUserDb.where('is_customer_read', 'in', ['0', 0]).where('customer_email', '==', customer.email);
                unsubQueryByCustEmail = queryByCustEmail.onSnapshot((querySnapshot) => {
                    const unreads = [];
                    querySnapshot.docs.forEach((doc) => {
                        unreads.push({ chatId: doc.id, ...doc.data() });
                    });
                    setCustMsgs(unreads);
                });
            }

            return () => {
                unsubQueryByAgentCode();
                unsubQueryByCustEmail();
            };
        }
        return () => null;
    }, [customer?.chat_agent_code, customer?.email]);

    function isIOS() {
        const browserInfo = navigator.userAgent.toLowerCase();

        if (browserInfo.match('iphone') || browserInfo.match('ipad')) {
            return true;
        }
        if (['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform)) {
            return true;
        }
        return false;
    }

    // create local notification
    useEffect(() => {
        if (typeof window === 'undefined' || isIOS()) return;

        const msgs = [...agentMsgs, ...custMsgs];
        notifyLocalUnreadMsgs(msgs);
    }, [agentMsgs, custMsgs]);

    return (
        <Box className={classes.container}>
            <Link href="/vendorportal/chat">
                <a>
                    <IconButton className={classes.iconButton}>
                        <StyledBadge badgeContent={agentMsgs.length + custMsgs.length} color="secondary">
                            <img alt="logo-chat" src="/assets/img/icon_chat.svg" className="icon" />
                        </StyledBadge>
                    </IconButton>
                </a>
            </Link>
            <ul>
                <li className="header">
                    <Box component="span">{t('user:messages')}</Box>
                </li>
                <li>{`You have ${agentMsgs.length + custMsgs.length} messages`}</li>
                <li className="viewAll">
                    <a
                        href="/vendorportal/chat"
                        style={{
                            color: `${ORANGE_PRIMARY}`,
                            fontWeight: 700,
                            fontSize: '12px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        {t('notification:viewAll')}
                        <ChevronRight style={{ fontSize: '14px' }} />
                    </a>
                </li>
            </ul>
        </Box>
    );
};
export default ChatNotification;
