/* eslint-disable linebreak-style */
/* eslint-disable import/prefer-default-export */
export const optionsConfigurations = (t) => [
    {
        aclCode: 'oms_lite_header_configurations',
        key: 'configurations',
        label: t('menu:menuConfiguration:parent'),
        children: [
            {
                aclCode: 'oms_lite_config_integrations',
                key: 'integrations',
                label: t('menu:menuConfiguration:integration'),
                url: '/configurations/integrations',
            },
            {
                aclCode: 'configuration_general',
                key: 'general',
                label: t('menu:menuConfiguration:general'),
                url: '/configurations/general',
            },
            {
                aclCode: 'configuration_product',
                key: 'product',
                label: t('menu:menuConfiguration:product'),
                url: '/configurations/product',
            },
            {
                aclCode: 'configuration_inventory',
                key: 'inventory',
                label: t('menu:menuConfiguration:inventory'),
                url: '/configurations/inventory',
            },
            {
                aclCode: 'configuration_price_location',
                key: 'configurationpricebylocation',
                label: t('menu:menuConfiguration:priceByLocation'),
                url: '/configurations/pricebylocation',
            },
            {
                aclCode: 'configuration_order',
                key: 'order',
                label: t('menu:menuConfiguration:order'),
                url: '/configurations/order',
            },
            {
                aclCode: 'configuration_invoice',
                key: 'configurationinvoice',
                label: t('menu:menuConfiguration:invoice'),
                url: '/configurations/invoice',
            },
            {
                aclCode: 'oms_lite_config_shipments',
                key: 'shipments',
                label: t('menu:menuConfiguration:shipment'),
                url: '/configurations/shipment',
            },
            {
                aclCode: 'configuration_rma',
                key: 'return',
                label: t('menu:menuConfiguration:return'),
                url: '/configurations/return',
            },
            {
                aclCode: 'configuration_logistix',
                key: 'logistix',
                label: t('menu:menuConfiguration:logistix'),
                url: '/configurations/logistix',
            },
            {
                aclCode: 'oms_lite_logistix_provider',
                key: 'logistixprovider',
                label: t('menu:menuConfiguration:logistixProvider'),
                url: '/configurations/logistixprovider',
            },
            {
                aclCode: 'pick_pack_configuration',
                key: 'pickpack',
                label: t('menu:menuConfiguration:pickPack'),
                url: '/configurations/pickpack',
            },
            {
                aclCode: 'oms_lite_header_mpadapter',
                key: 'mpadapter',
                label: t('menu:menuConfiguration:marketplaceAdapter'),
                url: '/configurations/mpadapter',
            },
            {
                aclCode: 'oms_lite_marketplace_feature',
                key: 'marketplacefeature',
                label: t('menu:menuConfiguration:marketplaceFeature'),
                url: '/configurations/marketplacefeature',
            },
            {
                aclCode: 'configuration_region_mapping',
                key: 'regionmapping',
                label: t('menu:menuConfiguration:regionMapping'),
                url: '/configurations/regionmapping',
            },
            {
                aclCode: 'configuration_tax_rules',
                key: 'configurationtaxrules',
                label: t('menu:menuConfiguration:taxRules'),
                url: '/configurations/taxrules',
            },
            {
                aclCode: 'acceptance_deadline',
                key: 'acceptancedeadline',
                label: t('menu:menuConfiguration:acceptanceDeadline'),
                url: '/configurations/acceptancedeadline',
            },
            {
                aclCode: 'configuration_vendor_portal',
                key: 'configurationvendorportal',
                label: t('menu:menuConfiguration:vendor'),
                url: '/configurations/vendorportal',
            },
            {
                aclCode: 'configuration_tada',
                key: 'configurationtada',
                label: t('menu:menuConfiguration:tada'),
                url: '/configurations/tada',
            },
            {
                aclCode: 'configuration_user',
                key: 'configurationuser',
                label: t('menu:menuConfiguration:user'),
                url: '/configurations/configurationuser',
            },
            {
                aclCode: 'configuration_user_guide',
                key: 'configurationuserguide',
                label: t('menu:menuConfiguration:userguide'),
                url: '/configurations/user-guide',
            },
            {
                aclCode: 'configuration_notification',
                key: 'notification',
                label: t('menu:menuConfiguration:notification'),
                url: '/configurations/notification',
            },
            {
                aclCode: 'configuration_open_api',
                key: 'configurationopenapi',
                label: t('menu:menuConfiguration:openApi'),
                url: '/configurations/openapi',
            },
            {
                aclCode: 'configuration_email_templates',
                key: 'configurationemailtemplates',
                label: t('menu:menuConfiguration:emailSettingAndTemplate'),
                url: '/configurations/emailtemplates',
            },
            {
                aclCode: 'configuration_security',
                key: 'configurationsecurity',
                label: t('menu:menuConfiguration:security'),
                url: '/configurations/security',
            },
            {
                aclCode: 'configuration_master_city',
                key: 'configurationmastercity',
                label: t('menu:menuConfiguration:mastercity'),
                url: '/configurations/mastercity',
            },
            {
                aclCode: 'configuration_finance',
                key: 'configurationfinance',
                label: t('menu:menuConfiguration:finance'),
                url: '/configurations/finance',
            },
            {
                aclCode: 'configuration_medbiz_chat',
                key: 'configurationmedbizchat',
                label: t('menu:menuConfiguration:medbizchat'),
                url: '/configurations/medbizchat',
            },
            {
                aclCode: 'oms_lite_vendor_level_config',
                key: 'sellerloyalty',
                label: t('menu:menuConfiguration:sellerloyalty'),
                url: '/configurations/sellerloyalty',
            },
            {
                aclCode: 'configuration_document_master',
                key: 'configurationdocumentmaster',
                label: t('menu:menuConfiguration:documentMaster'),
                url: '/configurations/documentmaster',
            },
            {
                aclCode: 'configuration_business_type',
                key: 'configurationbusinesstype',
                label: t('menu:menuConfiguration:businesstype'),
                url: '/configurations/businesstype',
            },
        ],
    },
];
