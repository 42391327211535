import { withTranslation } from '@i18n';
import { withApollo } from '@lib_apollo';

import Content from './component';
import Core from './core';

const FindAgent = (props) => <Core Content={Content} {...props} />;

FindAgent.getInitialProps = async () => ({ namespacesRequired: ['chat'] });
export default withApollo({ ssr: false })(withTranslation()(FindAgent));
